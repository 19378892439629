import {
  CredentialMetadata,
  JwtObject,
  ServerMetadataForIssuer,
  VerifiableCredentialJwt,
} from 'did-sdk';

import { VcModel, dexieDb } from '../dexie';

export class VcObject implements VcModel {
  public id: number | undefined;

  public vc: JwtObject<VerifiableCredentialJwt>;

  public serverMetadata: ServerMetadataForIssuer;

  public credential: CredentialMetadata;

  constructor(
    vc: JwtObject<VerifiableCredentialJwt>,
    serverMetadata: ServerMetadataForIssuer,
    credential: CredentialMetadata
  ) {
    this.vc = vc;
    this.serverMetadata = serverMetadata;
    this.credential = credential;
  }

  static async save(
    vc: JwtObject<VerifiableCredentialJwt>,
    serverMetadata: ServerMetadataForIssuer,
    credential: CredentialMetadata
  ) {
    const vcObj = new VcObject(vc, serverMetadata, credential);
    vcObj.id = await dexieDb.vc.put(vcObj);
    return vcObj;
  }

  static async all() {
    return dexieDb.vc.reverse().toArray();
  }

  static async clear() {
    return dexieDb.vc.clear();
  }
}

export default VcObject;
