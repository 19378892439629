import { JSONSchemaType } from 'ajv';
import axios from 'axios';
import urljoin from 'url-join';

import { ErrorWithLog, ajv } from '../../common/utils';

export type Locale = 'ja' | 'ja-JP' | 'en' | 'en-US' | '';

/**
 * localeを持つオブジェクトの配列から、指定言語のオブジェクトを取得する<br>
 * 完全一致 > languageが一致 > locale未指定 > 配列の先頭 の優先順位で返却する
 * @param objectList
 * @param locale
 */
export const selectLocale = <T>(objectList: (T & { locale?: Locale })[], locale: Locale): T => {
  let localeLang;
  let localeNone;
  for (const obj of objectList) {
    if (obj.locale) {
      if (obj.locale === locale) {
        // 完全一致
        return obj;
      }
      const localeParts = obj.locale.split('-');
      if (!localeLang && localeParts[0] === locale) {
        // languageが一致のオブジェクト
        localeLang = obj;
      }
    } else if (!localeNone) {
      // locale未指定のオブジェクト
      localeNone = obj;
    }
  }

  return localeLang || localeNone || objectList[0];
};

/**
 * クレデンシャルメタデータ
 */
export type CredentialMetadata = {
  display: {
    name: string;
    locale?: Locale;
    logo?: {
      url: string;
      alt_text?: string;
    };
    description?: string;
    background_color?: string;
    text_color?: string;
  }[];
  formats: {
    jwt_vc: {
      types: string[];
      cryptographic_binding_methods_supported: 'did'[];
      cryptographic_suites_supported: 'ES256K'[];
    };
  };
  claims: Record<
    string,
    {
      mandatory?: boolean;
      namespace?: string;
      value_type?: 'string' | 'number';
      display?: {
        name?: string;
        locale?: Locale;
      }[];
    }
  >;
};

/**
 * クレデンシャルメタデータ JSONSchemaType
 */
const jstCredentialMetadata: JSONSchemaType<CredentialMetadata> = {
  type: 'object',
  required: ['display', 'formats', 'claims'],
  properties: {
    display: {
      type: 'array',
      items: {
        type: 'object',
        required: ['name'],
        properties: {
          name: { type: 'string' },
          locale: { type: 'string', format: 'half-string', nullable: true },
          logo: {
            type: 'object',
            nullable: true,
            required: ['url'],
            properties: {
              url: { type: 'string', format: 'uri' },
              alt_text: { type: 'string', nullable: true },
            },
          },
          description: { type: 'string', nullable: true },
          background_color: { type: 'string', format: 'half-string', nullable: true },
          text_color: { type: 'string', format: 'half-string', nullable: true },
        },
      },
    },
    formats: {
      type: 'object',
      required: ['jwt_vc'],
      properties: {
        jwt_vc: {
          type: 'object',
          required: [
            'types',
            'cryptographic_binding_methods_supported',
            'cryptographic_suites_supported',
          ],
          properties: {
            types: {
              type: 'array',
              items: { type: 'string', format: 'half-string' },
            },
            cryptographic_binding_methods_supported: {
              type: 'array',
              items: { type: 'string', pattern: '^(did)$' },
            },
            cryptographic_suites_supported: {
              type: 'array',
              items: { type: 'string', pattern: '^(ES256K)$' },
            },
          },
        },
      },
    },
    claims: {
      type: 'object',
      required: [],
      additionalProperties: {
        type: 'object',
        properties: {
          mandatory: { type: 'boolean', nullable: true },
          namespace: { type: 'string', nullable: true },
          value_type: { type: 'string', nullable: true },
          display: {
            type: 'array',
            nullable: true,
            items: {
              type: 'object',
              properties: {
                name: { type: 'string', nullable: true },
                locale: { type: 'string', format: 'half-string', nullable: true },
              },
            },
          },
        },
      },
    },
  },
};

/**
 * Issuer向けサーバーメタデータ
 */
export type ServerMetadataForIssuer = {
  issuer: string;
  authorization_endpoint: string;
  token_endpoint: string;
  credential_endpoint: string;
  credentials_supported: Record<string, CredentialMetadata>;
  credential_issuer: {
    display: {
      name: string;
      locale?: Locale;
    }[];
  };
};

/**
 * Issuer向けサーバーメタデータ JSONSchemaType
 */
const jstServerMetadataForIssuer: JSONSchemaType<ServerMetadataForIssuer> = {
  type: 'object',
  required: [
    'issuer',
    'authorization_endpoint',
    'token_endpoint',
    'credential_endpoint',
    'credentials_supported',
    'credential_issuer',
  ],
  properties: {
    issuer: { type: 'string', format: 'uri' },
    authorization_endpoint: { type: 'string', format: 'uri' },
    token_endpoint: { type: 'string', format: 'uri' },
    credential_endpoint: { type: 'string', format: 'uri' },
    credentials_supported: {
      type: 'object',
      required: [],
      additionalProperties: jstCredentialMetadata,
    },
    credential_issuer: {
      type: 'object',
      required: ['display'],
      properties: {
        display: {
          type: 'array',
          items: {
            type: 'object',
            required: ['name'],
            properties: {
              name: { type: 'string' },
              locale: { type: 'string', format: 'half-string', nullable: true },
            },
          },
        },
      },
    },
  },
};

/**
 * サーバーメタデータ(Issuer)の取得
 * @param issuer Issuer URL
 * @returns サーバーメタデータ
 */
export const getServerMetadataForIssuer = async (
  issuer: string
): Promise<ServerMetadataForIssuer> => {
  // ディスカバリURL
  const serverMetadataUrl = urljoin(issuer, '/.well-known/openid-configuration');

  // 通信でサーバーメタデータの取得
  const serverMetadataRes = await axios.get(serverMetadataUrl);
  if (serverMetadataRes.status !== 200) {
    throw ErrorWithLog(serverMetadataRes.statusText);
  }
  const serverMetadata = serverMetadataRes.data;

  // サーバーメタデータのチェック
  const validate = ajv.compile(jstServerMetadataForIssuer);
  if (!validate(serverMetadata)) {
    throw ErrorWithLog(
      validate.errors && validate.errors[0]
        ? JSON.stringify(validate.errors[0])
        : 'Validation error'
    );
  }

  return serverMetadata;
};
