/* eslint-disable no-alert */
import {
  Alert,
  Card,
  CardContent,
  Container,
  Divider,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Pagination from '@mui/material/Pagination';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import copy from 'copy-to-clipboard';
import { useContextSet } from '../helpers/common';
import { TransActivityModel } from '../dexie';
import { TransActivity } from '../helpers/transActivity';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function PageTransActivity() {
  const { residentContext } = useContextSet();
  const { wallet, network, token } = residentContext;
  const [snackbar, setSnackbar] = useState<string>();
  const [activityList, setActivityList] = useState<TransActivityModel[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [conut, setCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState<TransActivityModel>();
  const [transValue, setTransValue] = useState<number>(0);

  const selectBlockInfoByPage = async (pages: number) => {
    if (wallet && network) {
      const transActivity = new TransActivity([], network.chainId, 0);
      const list = await transActivity.getEventList(network, wallet.address, pages, pageSize)
      setActivityList(list);
    }
  };

  const scanBlock = async () => {
    if (wallet && network && token?.contractList) {
      const contractLists = [{ address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' }];
      contractLists.pop();
      token.contractList?.forEach((item: any) => {
        if (item.rpcUrl === network?.rpcUrl) {
          contractLists.push(item);
        }
      })
      if (contractLists.length !== 0) {
        // 使用保存的nriProvider
        const block = await network.nriProvider.getBlockNumber();
        const transActivity = new TransActivity([], network.chainId, 0);
        contractLists.forEach(async (item: any) => {
          const scanRes = await transActivity?.scan(item, block, network, wallet.address);
          if (scanRes?.isUpdate) {
            selectBlockInfoByPage(0);
          }
        })
      }
    }
  };

  const getActivityInfo = async () => {
    if (wallet?.address && network) {
      const transActivity = new TransActivity([], "", 0);
      const list = await transActivity.getEventList(network, wallet.address, 0, pageSize)
      setActivityList(list);
      const counts = await transActivity.getEventListAll(network, wallet.address)
      if ((counts % pageSize) === 0) {
        setCount(counts / pageSize);
      } else {
        setCount(Math.ceil(counts / pageSize));
      }
    }
  };

  useEffect(() => {
    getActivityInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  useEffect(() => {
    const InterVal = setInterval(() => {
      scanBlock();
    }, 5000)
    return () => {
      clearInterval(InterVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  const showSnackbar = (msg: string) => {
    setSnackbar(msg);
  };

  const closeSnackbar = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(undefined);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageSize(pageSize);
    setPage(value);
    const startCount = (value - 1) * pageSize;
    selectBlockInfoByPage(startCount);
  };

  const openTransInfo = async (event: TransActivityModel) => {
    setOpen(true);
    const values = Number(event.value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const valueInt = parseInt(event.value, 10);
    if ((values - valueInt) > 0) {
      setTransValue(values)
    } else {
      setTransValue(valueInt)
    }
    setActivity(event);
  };

  const closeTransInfo = () => {
    setOpen(false);
    setActivity(undefined);
    setTransValue(0)
  };

  const copyHash = (transactionHash: any) => {
    copy(transactionHash);
    showSnackbar('コピーに成功しました。');
  }

  if (!network || !wallet) {
    return <Container />;
  }

  return (
    <>
      <Container maxWidth="md" sx={{ paddingX: '8px' }}>
        <Typography variant="h6" sx={{ marginTop: '16px' }}>
          アクティビティ
        </Typography>
        {activityList.map((row) => (
          <div key={row.transactionHash}>
            <Card variant="outlined" sx={{ marginTop: '8px', cursor: 'pointer' }} onClick={() => openTransInfo(row)}>
              <CardContent>
                <div>{row.from === wallet.address ? <span style={{ fontSize: "large", color: 'green' }}>送金 {row.symbol}</span> : <span style={{ fontSize: "large", color: 'red' }}>受領 {row.symbol}</span>}{row.from === wallet.address ? <div style={{ float: 'right', fontSize: "large", color: 'green' }}><span>{'-'}{row.value} {row.symbol}</span></div> : <div style={{ float: 'right', fontSize: "large", color: 'red' }}><span>{'+'}{row.value} {row.symbol}</span></div>}</div>
                <Typography fontSize={12}>
                  {dayjs.unix(row.transTime).format('YYYY-MM-DD HH:mm:ss')} · {row.from === wallet.address ? '移動先：' : '移動元：'}{row.from === wallet.address ? row.to : row.from}
                </Typography>
              </CardContent>
            </Card>
            <Divider sx={{ marginY: '10px' }} />

          </div>
        )
        )}
        {
          conut === 0 ? '' :
            <Stack spacing={2}>
              <Pagination page={page} count={conut} onChange={handleChange} color="primary" />
            </Stack>

        }
        <br />
        <br />
        <br />
      </Container>
      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
          {snackbar}
        </Alert>
      </Snackbar>
      <div>
        <BootstrapDialog
          open={open}
          onClose={closeTransInfo}
          aria-labelledby="customized-dialog-title"
        >
          <BootstrapDialogTitle id="customized-dialog-title" onClose={closeTransInfo}>
            {activity?.from === wallet.address ? '送金 ' : '受領 '}{activity?.symbol}
          </BootstrapDialogTitle>
          <DialogContent>
            <div style={{ minWidth: 560 }}>
              <div style={{ float: 'left' }}>
                <span style={{ color: '#24272a', fontWeight: 'bold' }}>ステータス</span>
                <br />
                <span style={{ color: '#28a745' }}>確認されました</span>
              </div>

              <div style={{ float: 'right' }}>
                <a style={{ float: 'right', color: '#0376c9', fontSize: 15, cursor: 'pointer' }} onClick={() => copyHash(activity?.transactionHash)}>トランザクションIDをコピー</a>
                <br />
                <a style={{ float: 'right', color: 'black', fontSize: 15 }}>{activity ? dayjs.unix(activity?.transTime).format('YYYY-MM-DD HH:mm:ss') : ''}</a>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
            <br />
            <div>
              <div>
                <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動元</div>
                <div style={{ color: 'black', fontSize: 15 }}>{activity?.from}</div>
              </div>
              <br />
              <div>
                <div style={{ color: '#24272a', fontWeight: 'bold' }}>移動先</div>
                <div style={{ color: 'black', fontSize: 15 }}>{activity?.to}</div>
              </div>
              <br />
              <div>
                <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>金額</div>
                <div style={{ float: 'right' }}>{activity?.from === wallet.address ? '- ' : '+ '}{transValue} {activity?.symbol}</div>
              </div>
              <div style={{ clear: 'both' }} />
              <br />
              <div>
                <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>ガス使用量(単位)</div>
                <div style={{ float: 'right' }}>{activity?.gasUsed}</div>
              </div>
              <div style={{ clear: 'both' }} />
              <br />
              <div>
                <div style={{ color: '#24272a', fontWeight: 'bold', float: 'left' }}>ガス代</div>
                <div style={{ float: 'right' }}>{activity?.gasPrice}</div>
              </div>
            </div>

          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}

export default PageTransActivity;
