/* eslint-disable no-alert */

/* eslint-disable no-console */
import { Create as IconCreate } from '@mui/icons-material';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid, 
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ethers } from 'ethers';
import React, { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import ServiceManager from '../helpers/ServiceManager';
import { useContextSet } from '../helpers/common';
import { TransActivity } from '../helpers/transActivity';

export function PageWallet() {
  const { residentContext } = useContextSet();
  const { wallet, network, token } = residentContext;
  const [snackbar, setSnackbar] = useState<string>();
  const [walletAddress, setWalletAddress] = useState('loading...');
  const [privateKey, setPrivateKey] = useState('loading...');
  const [showPrivateKey, setShowPrivateKey] = useState(false);
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [formError, setFromError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [walletList, setWalletList] = useState([{ address: '', privateKeys: '' }]);
  const [open, setOpen] = useState(false);

  const getWallet = useCallback(async () => {
    if (wallet?.address) {
      setWalletAddress(wallet.address);
      setWalletList(wallet.walletList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.address]);

  useEffect(() => {
    getWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet?.address]);

  const handleChange1 = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'password':
        setPassword(() => event.target.value);
        if (event.target.value === repassword) {
          setFromError(false);
        } else {
          setFromError(true);
        }
        if (repassword === '') {
          setFromError(false);
        }
        break;
      case 'repassword':
        setRepassword(() => event.target.value);
        if (event.target.value !== password) {
          setFromError(true);
        } else {
          setFromError(false);
        }
        break;
      default:
        break;
    }
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.id) {
      setPasswordInput(() => event.target.value);
      setPasswordError(false);
    }
  };

  const showSnackbar = (msg: string) => {
    setSnackbar(msg);
  };

  const closeSnackbar = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(undefined);
  };

  /**
   * アカウント作成
   * @returns
   */
  const createAccount = async () => {
    if (!wallet || !network) {
      return;
    }
    if (password === '') {
      alert('パスワードを入力してください！');
      return;
    }
    if (repassword === '' || password === '') {
      alert('パスワード確認を入力してください！');
      return;
    }

    const AccountService = ServiceManager.getAccountService();
    const pwd = password;
    await AccountService?.createNewVaultAndKeychain(pwd);

    // privateKey获取方法
    const selectedAddress = await ServiceManager.getPreferencesService()?.getSelectedAddress();
    // eslint-disable-next-line no-shadow
    const privateKey = await ServiceManager.getAccountService()?.exportAccount(
      pwd as string,
      selectedAddress as string
    );
    // 使用保存的nriProvider
    const newWallet = new ethers.Wallet(privateKey as string, network.nriProvider.provider);
    wallet.address = selectedAddress;
    const wallets = { address: '', privateKeys: '', };
    wallets.address = selectedAddress as string;
    walletList.push(wallet.walletList);
    walletList.push(wallets);
    wallet.walletList = walletList;
    console.log('create account:', newWallet);
    wallet.save();
    const block = await network.nriProvider.getBlockNumber();
    const transActivity = new TransActivity([], "", 0);
    if (token) {
      const contractLists = [{ address: '', symbol: '', decimal: '18', rpcUrl: '', name: '' }];
      contractLists.pop()
      token.contractList?.forEach((item: any) => {
        if (item.rpcUrl === network?.rpcUrl) {
          contractLists.push(item)
        }
      })
      contractLists.forEach((item: any) => {
        transActivity.setScannedBlock(block, wallet.address, item.address, network);
        wallet.loadActivity(network, item.address);
        wallet.setWallet(network, item.address);
      })  
    }
    showSnackbar('アカウントを作成しました。');
  };

  /**
   * アカウント削除
   * @returns
   */
  const deleteAccount = async () => {
    if (!wallet || !network) {
      return;
    }
    // reset sdk state
    await ServiceManager.getDefault().resetState();

    wallet.address = '';
    setPassword('');
    setRepassword('');
    setWalletList([]);
    setShowPrivateKey(false);
    wallet.clear();
    showSnackbar('アカウントを削除しました。');
  };

  const privateKeyDispay = () => {
    if (!showPrivateKey) {
      setOpen(true);
    }
    if (showPrivateKey) {
      setShowPrivateKey(!showPrivateKey);
      setPrivateKey('loading...');
    }
  };

  if (!network || !wallet) {
    return <Container />;
  }

  if (!wallet.address) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Typography variant="h5" sx={{ marginBottom: '16px' }}>
            アカウント
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              作成されていません。
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <TextField
                    id="password"
                    label="パスワード"
                    fullWidth
                    value={password}
                    onChange={handleChange1}
                    style={{ marginTop: '16px' }}
                    required
                    type="password"
                  />
                  <TextField
                    id="repassword"
                    label="パスワード確認"
                    fullWidth
                    value={repassword}
                    onChange={handleChange1}
                    style={{ marginTop: '16px' }}
                    required
                    type="password"
                    error={formError}
                    helperText={formError ? '二回記入したパスワードが一致しません。' : ''}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                size="large"
                startIcon={<IconCreate />}
                onClick={createAccount}
              >
                アカウント新規作成
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
            {snackbar}
          </Alert>
        </Snackbar>
      </>
    );
  }

  const handleClose = () => {
    setOpen(false);
    setPasswordError(false);
  };

  const handleConfirm = async () => {
    if (passwordInput === '') {
      setPasswordError(true);
      return;
    }
    try {
      const accountService = ServiceManager.getAccountService();
      await accountService?.submitPassword(passwordInput);
      const privateKeys = await accountService?.exportAccount(
        passwordInput as string,
        wallet.address as string
      );
      setPrivateKey(privateKeys as string);
      setOpen(false);
      setShowPrivateKey(!showPrivateKey);
      setPasswordInput('');
    } catch (error) {
      setPasswordError(true);
    }
  };

  return (
    <>
      <Container maxWidth="md" sx={{ paddingX: '8px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader title="アカウント" />
              <CardContent>
                <TextField
                  label="アドレス"
                  fullWidth
                  multiline
                  value={walletAddress}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="秘密鍵"
                  fullWidth
                  value={privateKey}
                  InputProps={{
                    readOnly: true,
                  }}
                  style={{ marginTop: '16px' }}
                  type={showPrivateKey ? 'text' : 'password'}
                />
              </CardContent>
              <Divider sx={{ marginX: '8px' }} />
              <CardActions>
                <Button size="small" onClick={() => privateKeyDispay()}>
                  秘密鍵を表示
                </Button>
                <Button size="small" color="error" onClick={deleteAccount}>
                  削除
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <br />
        <br />
        <br />
      </Container>
      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
          {snackbar}
        </Alert>
      </Snackbar>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">パスワード</DialogTitle>
          <DialogContent>
            <TextField
              id="passwordInput"
              label="パスワード"
              fullWidth
              value={passwordInput}
              onChange={handleChangePassword}
              style={{ marginTop: '16px', minWidth: 500, minHeight: 80 }}
              required
              type="password"
              error={passwordError}
              helperText={passwordError ? 'パスワードが間違っています。' : ''}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>取 消</Button>
            <Button onClick={handleConfirm} autoFocus>
              確 認
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default PageWallet;
