import { selectLocale } from 'did-sdk';
import React from 'react';

import { VcObject } from '../helpers/vc';

export function showVcCard(vc: VcObject) {
  const display = selectLocale(vc.credential.display, 'ja');
  const logo = display.logo ? (
    <img
      src={display.logo.url}
      alt={display.logo.alt_text || 'logo'}
      width="48"
      style={{ position: 'absolute', right: '12px' }}
    />
  ) : (
    <div />
  );
  return (
    <div
      style={{
        position: 'relative',
        padding: '0.5rem',
        border: 'solid 1px #ccc',
        borderRadius: '6px',
        backgroundColor: display.background_color,
        color: display.text_color,
      }}
    >
      {logo}
      <div
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          fontSize: '1.2rem',
          fontWeight: 'bold',
        }}
      >
        {display.name}
      </div>
      <div style={{ fontSize: '1rem', marginTop: '1rem' }}>{display.description}</div>
    </div>
  );
}

export default showVcCard;
