import { Save as IconSave } from '@mui/icons-material';
import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { Log } from 'did-sdk';
import React, {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

import { setupContextSet, useContextSet } from '../helpers/common';
import { Settings } from '../helpers/settings';

const STATUS = {
  INIT: 0,
  START: 1,
};

/**
 * 設定画面
 * @returns
 */
export function PageSettings() {
  const { nowLoadingContext, settingsContext, didContext } = useContextSet();
  const [status, setStatus] = useState(STATUS.INIT);
  const [selectCreater, setSelectCreater] = useState('');
  const [createrList, setCreaterList] = useState<ReactElement[]>();
  const [open, setOpen] = useState(false);
  const [urlOperation, setUrlOperation] = useState('');
  const [urlResolve, setUrlResolve] = useState('');

  useLayoutEffect(() => {
    if (status === STATUS.INIT) {
      nowLoadingContext.setNowLoading(true);
    }
  });

  const init = async () => {
    setupContextSet(settingsContext, didContext).then(() => {
      setStatus(STATUS.START);
    });
  };

  const main = async () => {
    // 設定の読み込み
    const settings = await Settings.load();
    setSelectCreater(settings.selectCreater);
    setUrlOperation(settings.urlOperation);
    setUrlResolve(settings.urlResolve);

    // Creater List生成
    if (didContext.didManage.didMgr) {
      const list = [];
      for (const createrKey of didContext.didManage.didMgr.getCreaterList()) {
        list.push(
          <MenuItem key={`ck-${createrKey}`} value={createrKey}>
            {createrKey}
          </MenuItem>
        );
      }
      setCreaterList(list);
    }

    nowLoadingContext.setNowLoading(false);
  };

  useEffect(() => {
    Log.debug('STATUS:', status);
    switch (status) {
      case STATUS.INIT:
        init();
        break;
      case STATUS.START:
        main();
        break;
      default:
        break;
    }
    // 契機はstatusの変更時のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'url-operation':
        setUrlOperation(() => event.target.value);
        break;
      case 'url-resolve':
        setUrlResolve(() => event.target.value);
        break;
      default:
        break;
    }
  };

  const handleCreaterChange = (event: SelectChangeEvent<string>) => {
    setSelectCreater(() => event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const saveSettings = async () => {
    // 設定の保存
    const settings = new Settings();
    settings.selectCreater = selectCreater;
    settings.urlOperation = urlOperation;
    settings.urlResolve = urlResolve;
    await settings.save();
    // settingsContext.setSettings(settings)

    // 再セット
    await setupContextSet(settingsContext, didContext, true);

    handleOpen();
  };

  const resetSettings = async () => {
    await Settings.clear();
    const settings = new Settings();
    settingsContext.setSettings(settings);
    setSelectCreater(settings.selectCreater);
    setUrlOperation(settings.urlOperation);
    setUrlResolve(settings.urlResolve);
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
        <Typography variant="h5" sx={{ marginBottom: '16px' }}>
          Settings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="select-creater-lablel">DID生成インターフェース</InputLabel>
              <Select
                labelId="select-creater-lablel"
                id="select-creater"
                value={selectCreater}
                label="DID生成インターフェース"
                onChange={handleCreaterChange}
              >
                {createrList}
              </Select>
            </FormControl>
            <Typography variant="caption" display="block">
              <ul>
                <li>ion-with-challenge : デフォルト(公開ION Betaノード用)</li>
                <li>ion-no-registered : ブロックチェーンに書き込まない</li>
                <li>ion-no-challenge : Challenge要求を行わない(カスタムIONノード用)</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="url-operation"
              fullWidth
              label="Operation URL"
              variant="outlined"
              value={urlOperation}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="url-resolve"
              fullWidth
              label="Resolve URL"
              variant="outlined"
              value={urlResolve}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={8}>
            <Button fullWidth variant="contained" startIcon={<IconSave />} onClick={saveSettings}>
              保存
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="contained" color="error" onClick={resetSettings}>
              リセット
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          設定を保存しました。
        </Alert>
      </Snackbar>
    </>
  );
}

export default PageSettings;
