import dayjs from 'dayjs';

import { ActivityModel, dexieDb } from '../dexie';

const addActivity = async (type: string, content: string, options = {}): Promise<ActivityModel> => {
  const createdAt = dayjs().format();
  const id = await dexieDb.activity.put({ type, content, createdAt, options });
  return { id, type, content, createdAt, options };
};

export const ACTIVITY_TYPE = {
  RECEIVED_VC: 'RECEIVED_VC',
  PRESENTED_VP: 'PRESENTED_VP',
};

export class Activity {
  static async receivedVc(issuerName: string, vcName: string) {
    return addActivity(
      ACTIVITY_TYPE.RECEIVED_VC,
      `${issuerName} から ${vcName} を受け取りました。`
    );
  }

  static async presentedVp(verifierName: string, vcName: string) {
    return addActivity(ACTIVITY_TYPE.RECEIVED_VC, `${verifierName} に ${vcName} を提示しました。`);
  }

  static async all() {
    return dexieDb.activity.reverse().toArray();
  }

  static async clear() {
    return dexieDb.activity.clear();
  }
}
