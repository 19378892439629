import {
  IonDid,
  IonDocumentModel,
  IonKey,
  IonPublicKeyModel,
  IonPublicKeyPurpose,
  IonRequest,
  IonServiceModel,
  JwkEs256k,
} from '@decentralized-identity/ion-sdk';
import axios from 'axios';
import { argon2id } from 'hash-wasm';

import { Log } from '../../common/log';
import { ErrorWithLog } from '../../common/utils';
import { DidDocument } from '../DidDocument';
import { Secp256kPrivateJwk, Secp256kPublicJwk } from '../DidKey';
import { IDidCreater } from '../DidManager';
import { DidObject } from '../DidObject';

const randomHexString = () => {
  const size = Math.floor(Math.random() * Math.floor(500));
  const randomString = [...Array(size)]
    .map(() => Math.floor(Math.random() * 16).toString(16))
    .join('');
  return Buffer.from(randomString).toString('hex');
};

const submitIonRequest = async (
  getChallengeUri: string,
  solveChallengeUri: string,
  requestBody: string
) => {
  // Challengeリクエスト
  const challengeResponse = await axios.get(getChallengeUri);
  if (challengeResponse.status !== 200) {
    throw ErrorWithLog(challengeResponse.statusText);
  }
  Log.debug('challengeResponse:', challengeResponse.data);

  const { challengeNonce, largestAllowedHash } = challengeResponse.data;
  const validDuration = challengeResponse.data.validDurationInMinutes * 60 * 1000;

  // Answer生成
  const startTime = Date.now();
  let answerHash = '';
  let answerNonce = '';
  do {
    answerNonce = randomHexString();
    // ソース流用の為
    // eslint-disable-next-line no-await-in-loop
    answerHash = await argon2id({
      password: Buffer.from(answerNonce, 'hex').toString() + requestBody,
      salt: Buffer.from(challengeNonce, 'hex'),
      parallelism: 1,
      iterations: 1,
      memorySize: 1000,
      hashLength: 32, // output size = 32 bytes
      outputType: 'hex',
    });
    Log.debug(answerHash);
    Log.debug(largestAllowedHash);
  } while (answerHash > largestAllowedHash && Date.now() - startTime < validDuration);
  if (Date.now() - startTime > validDuration) {
    throw ErrorWithLog('ValidDuration Over');
  }

  // Createリクエスト
  const createResponse = await axios.post(solveChallengeUri, requestBody, {
    headers: {
      'Content-Type': 'application/json',
      'Challenge-Nonce': challengeNonce,
      'Answer-Nonce': answerNonce,
    },
  });
  if (createResponse.status !== 200) {
    throw ErrorWithLog(createResponse.statusText);
  }
  Log.debug('createResponse:', createResponse.data);
  return createResponse.data as DidDocument;
};

export const generateIonKeySet = async (signingKeyId: string) => {
  // 鍵生成
  const recoveryKeyPair = await IonKey.generateEs256kOperationKeyPair();
  const updateKeyPair = await IonKey.generateEs256kOperationKeyPair();
  const signingDocKeyPair = await IonKey.generateEs256kDidDocumentKeyPair({
    id: signingKeyId,
    purposes: [IonPublicKeyPurpose.Authentication, IonPublicKeyPurpose.AssertionMethod],
  });
  return {
    recoveryKeyPair,
    updateKeyPair,
    signingDocKeyPair,
  };
};

export const createRequestInput = (
  publicKey: IonPublicKeyModel,
  updateKey: JwkEs256k,
  recoveryKey: JwkEs256k,
  services?: IonServiceModel[]
) => {
  const document: IonDocumentModel = {
    publicKeys: [publicKey],
  };
  if (services) {
    document.services = services;
  }
  return {
    document,
    updateKey,
    recoveryKey,
  };
};

/**
 * DID Creater(ION Challengeあり版)
 */
export class IonDidCreaterWithChallenge implements IDidCreater {
  // implements Method
  // eslint-disable-next-line class-methods-use-this
  get key() {
    return 'ion-with-challenge';
  }

  public operationsUrl: string;

  public challengeUrl: string;

  /**
   *
   * @param operationsUrl Operations URL
   * @param challengeUrl Challenge URL
   */
  constructor(
    operationsUrl = 'https://beta.ion.msidentity.com/api/v1.0/operations',
    challengeUrl = 'https://beta.ion.msidentity.com/api/v1.0/proof-of-work-challenge'
  ) {
    this.operationsUrl = operationsUrl;
    this.challengeUrl = challengeUrl;
  }

  /**
   *
   * @param signingKeyId 署名鍵ID
   * @returns DIDオブジェクト
   */
  async create(signingKeyId: string, services?: IonServiceModel[]) {
    // 鍵生成
    const { recoveryKeyPair, updateKeyPair, signingDocKeyPair } = await generateIonKeySet(
      signingKeyId
    );

    // DID作成リクエスト
    const input = createRequestInput(
      signingDocKeyPair[0],
      updateKeyPair[0],
      recoveryKeyPair[0],
      services
    );

    const createRequest = IonRequest.createCreateRequest(input);
    const longFormDid = IonDid.createLongFormDid(input);
    const longFormSuffixData = longFormDid.substring(longFormDid.lastIndexOf(':') + 1);
    Log.debug('createRequest:', createRequest);

    // エンドポイントへリクエスト
    const createResponse = await submitIonRequest(
      this.challengeUrl,
      this.operationsUrl,
      JSON.stringify(createRequest)
    );

    // DidObjectにして返却
    return DidObject.createByDidString(
      [createResponse.didDocument.id, longFormSuffixData].join(':'),
      signingKeyId,
      createResponse.didDocumentMetadata.method.published,
      {
        signing: {
          public: signingDocKeyPair[0].publicKeyJwk as Secp256kPublicJwk,
          private: signingDocKeyPair[1] as Secp256kPrivateJwk,
        },
        update: {
          public: updateKeyPair[0],
          private: updateKeyPair[1] as Secp256kPrivateJwk,
        },
        recovery: {
          public: recoveryKeyPair[0],
          private: recoveryKeyPair[1] as Secp256kPrivateJwk,
        },
      }
    );
  }
}

export default IonDidCreaterWithChallenge;
