/* eslint-disable class-methods-use-this */
import { MetaMaskKeyring as QRHardwareKeyring } from '@keystonehq/metamask-airgapped-keyring';
import { AbstractAccountAdapter, AccountConfig, AccountState } from 'nft-core-sdk';

// eslint-disable-next-line import/no-cycle
import { dispatch } from '../store';
import { updateStateMap } from '../store/slice/services';

class AccountAdapter extends AbstractAccountAdapter<AccountState, AccountConfig> {
  initialize(): void {
    // 何もしない
  }

  configure(): Partial<AccountConfig> {
    const additionalKeyrings = [QRHardwareKeyring];

    return {
      keyringTypes: additionalKeyrings,
    };
  }

  deviceType(): string {
    return 'web';
  }

  async supportedBiometryType(): Promise<any> {
    return null;
  }

  async resetGenericPassword(): Promise<boolean> {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async setGenericPassword(password: string, type: string): Promise<boolean> {
    return false;
  }

  onStateUpdate(serviceState: Partial<AccountState>, serviceName: string): void {
    if (dispatch) {
      dispatch(updateStateMap({ serviceState, serviceName }));
    }
  }
}
export default AccountAdapter;
