/* eslint-disable class-methods-use-this */
import { AbstractAdapter, IConfig, PreferencesState } from 'nft-core-sdk';

// eslint-disable-next-line import/no-cycle
import { dispatch } from '../store';
import { updateStateMap } from '../store/slice/services';

export class PreferencesAdapter extends AbstractAdapter<PreferencesState, IConfig> {
  configure(): Partial<IConfig> {
    return {};
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  initialize(): void {}

  onStateUpdate(serviceState: Partial<PreferencesState>, serviceName: string): void {
    if (dispatch) {
      dispatch(updateStateMap({ serviceState, serviceName }));
    }
  }
}

export default PreferencesAdapter;
