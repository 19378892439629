/* eslint-disable no-console */
const logMethods = {
  info: console.info,
  warn: console.warn,
  error: console.error,
  debug: console.debug,
};

/**
 * ログ出力メソッドの設定
 * @param __namedParameters.info INFOログ出力メソッド
 * @param __namedParameters.warn WARNログ出力メソッド
 * @param __namedParameters.error ERRORログ出力メソッド
 * @param __namedParameters.debug DEBUGログ出力メソッド
 */
const set = ({
  info,
  warn,
  error,
  debug,
}: {
  info?: (message: unknown, ...args: unknown[]) => void;
  warn?: (message: unknown, ...args: unknown[]) => void;
  error?: (message: unknown, ...args: unknown[]) => void;
  debug?: (message: unknown, ...args: unknown[]) => void;
}) => {
  if (info) {
    logMethods.info = info;
  }
  if (warn) {
    logMethods.warn = warn;
  }
  if (error) {
    logMethods.error = error;
  }
  if (debug) {
    logMethods.debug = debug;
  }
};

/**
 * INFOログ出力
 * @param message
 * @param args
 */
const info = (message: unknown, ...args: unknown[]) => {
  logMethods.info(message, ...args);
};

/**
 * ログ出力
 * @param message
 * @param args
 */
const log = (message: unknown, ...args: unknown[]) => {
  logMethods.info(message, ...args);
};

/**
 * WARNログ出力
 * @param message
 * @param args
 */
const warn = (message: unknown, ...args: unknown[]) => {
  logMethods.warn(message, ...args);
};

/**
 * ERRORログ出力
 * @param message
 * @param args
 */
const error = (message: unknown, ...args: unknown[]) => {
  logMethods.error(message, ...args);
};

/**
 * DEBUGログ出力
 * @param message
 * @param args
 */
const debug = (message: unknown, ...args: unknown[]) => {
  logMethods.debug(message, ...args);
};

export const Log = {
  set,
  log,
  info,
  warn,
  error,
  debug,
};
export default Log;
