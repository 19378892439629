import { ExpandMore as IconExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  TextField,
  Typography,
} from '@mui/material';
import { Log } from 'did-sdk';
import React, { useEffect, useLayoutEffect, useState } from 'react';

import { setupContextSet, useContextSet } from '../helpers/common';
import { PrivateKeyTool } from '../helpers/privateKeyTool';

const STATUS = {
  INIT: 0,
  START: 1,
};

export function PageDid() {
  const { nowLoadingContext, settingsContext, didContext } = useContextSet();
  const [status, setStatus] = useState(STATUS.INIT);
  const [privateKeys, setPrivateKeys] = useState({
    signing: '',
    recovery: '',
    update: '',
  });

  useLayoutEffect(() => {
    if (status === STATUS.INIT) {
      nowLoadingContext.setNowLoading(true);
    }
  });

  const init = async () => {
    setupContextSet(settingsContext, didContext).then(() => {
      setStatus(STATUS.START);
    });
  };

  const main = async () => {
    if (didContext.didManage.didObj) {
      setPrivateKeys({
        signing: JSON.stringify(
          (await PrivateKeyTool.load(didContext.didManage.didObj.signingKeyId))?.privateKey,
          null,
          2
        ),
        recovery: JSON.stringify(
          (await PrivateKeyTool.load(PrivateKeyTool.RESERVE_ID.RECOVERY))?.privateKey,
          null,
          2
        ),
        update: JSON.stringify(
          (await PrivateKeyTool.load(PrivateKeyTool.RESERVE_ID.UPDATE))?.privateKey,
          null,
          2
        ),
      });
    }

    nowLoadingContext.setNowLoading(false);
  };

  useEffect(() => {
    Log.debug('STATUS:', status);
    switch (status) {
      case STATUS.INIT:
        init();
        break;
      case STATUS.START:
        main();
        break;
      default:
        break;
    }
    // 契機はstatusの変更時のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (!didContext.didManage.didObj) {
    return <Container maxWidth="sm" sx={{ paddingX: '8px' }} />;
  }

  return (
    <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        DID詳細
      </Typography>
      <Accordion>
        <AccordionSummary expandIcon={<IconExpandMore />}>
          <Typography>DID(Long)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="DID(Long)"
            fullWidth
            multiline
            maxRows={8}
            value={didContext.didManage.didObj.didLong}
            InputProps={{
              readOnly: true,
              sx: { fontSize: '12px' },
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconExpandMore />}>
          <Typography>SigningPrivateKey</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="SigningPrivateKey"
            fullWidth
            multiline
            maxRows={8}
            value={privateKeys.signing}
            InputProps={{
              readOnly: true,
              sx: { fontSize: '12px' },
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconExpandMore />}>
          <Typography>RecoveryPrivateKey</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="RecoveryPrivateKey"
            fullWidth
            multiline
            maxRows={8}
            value={privateKeys.recovery}
            InputProps={{
              readOnly: true,
              sx: { fontSize: '12px' },
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconExpandMore />}>
          <Typography>UpdatePrivateKey</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            label="UpdatePrivateKey"
            fullWidth
            multiline
            maxRows={8}
            value={privateKeys.update}
            InputProps={{
              readOnly: true,
              sx: { fontSize: '12px' },
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default PageDid;
