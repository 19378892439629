import { JSONSchemaType } from 'ajv';
import axios from 'axios';

import { ErrorWithLog, ajv } from '../common/utils';
import { JwtPayload, jstJwtPayload } from './DidJwt';

/**
 * サポートするクレデンシャルステータスのタイプ
 */
export type CredentialStatusType = 'CredentialStatusList2017';

/**
 * クレデンシャルステータスのタイプ
 */
export const CREDENTIAL_STATUS_TYPE: Record<string, CredentialStatusType> = {
  CredentialStatusList2017: 'CredentialStatusList2017',
};

/**
 * Verifiable Credential 定義<br>
 * https://www.w3.org/TR/vc-data-model/#basic-concepts
 */
export type VerifiableCredential = {
  '@context': string[];
  id?: string;
  type: string[];
  issuer: string;
  issuanceDate: string;
  expirationDate: string;
  credentialSubject: Record<string, unknown> | Record<string, unknown>[];
  credentialStatus?: {
    id: string;
    type: CredentialStatusType;
  };
};

/**
 * Verifiable Credential JSONSchemaType
 */
export const jstVerifiableCredential: JSONSchemaType<VerifiableCredential> = {
  type: 'object',
  required: ['@context', 'type', 'issuer', 'issuanceDate', 'expirationDate', 'credentialSubject'],
  properties: {
    '@context': {
      type: 'array',
      items: { type: 'string' },
    },
    id: {
      type: 'string',
      format: 'half-string',
      nullable: true,
    },
    type: {
      type: 'array',
      items: { type: 'string', format: 'half-string' },
    },
    issuer: { type: 'string', format: 'half-string' },
    issuanceDate: { type: 'string', format: 'date-time' },
    expirationDate: { type: 'string', format: 'date-time' },
    credentialSubject: {
      anyOf: [{ type: 'object' }, { type: 'array', items: { type: 'object' } }],
    },
    credentialStatus: {
      type: 'object',
      nullable: true,
      required: ['id', 'type'],
      properties: {
        id: { type: 'string', format: 'half-string' },
        type: { type: 'string', pattern: '^(CredentialStatusList2017)$' },
      },
    },
  },
};

/**
 * VerifiableCredential.type のタイプ指定のインデックス
 */
export const VERIFIABLE_CREDENTIAL_TYPE_INDEX = {
  VERIFIABLE_TYPE: 0,
  PRIMARY_TYPE: 1,
};

/**
 * Verifiable Credential JWT 定義
 */
export type VerifiableCredentialJwt = JwtPayload & {
  vc: VerifiableCredential;
};

/**
 * Verifiable Credential JWT JSONSchemaType
 */
export const jstVerifiableCredentialJwt: JSONSchemaType<VerifiableCredentialJwt> = {
  type: 'object',
  required: [...jstJwtPayload.required, 'vc'],
  properties: {
    ...jstJwtPayload.properties,
    vc: jstVerifiableCredential,
  },
};

export type CredentialStatusList2017VC = {
  claim: {
    id: string;
    currentStatus: string;
    statusReason: string;
  };
  issuer: string;
  issued: string;
  proof?: Record<string, unknown>;
};

/**
 * Credential Status List 2017 定義
 */
export type CredentialStatusList2017 = {
  id: string;
  description: string;
  verifiableCredential: CredentialStatusList2017VC[];
  proof?: Record<string, unknown>;
};

/**
 * VCの最新ステータスを取得する(CredentialStatus2017)
 * @param vc ステータスを取得したいVC
 * @returns
 */
export const getCurrentCredentialStatus2017 = async (vc: VerifiableCredential) => {
  if (!vc.credentialStatus) {
    // credentialStatusの指定が無い場合はActive
    return 'Active';
  }

  // ステータスを取得(通信)
  const credentialStatusRes = await axios.get(vc.credentialStatus.id);
  if (credentialStatusRes.status !== 200) {
    throw ErrorWithLog(credentialStatusRes.statusText);
  }
  const credentialStatus = credentialStatusRes.data;

  const validateSchema: JSONSchemaType<CredentialStatusList2017> = {
    type: 'object',
    required: ['id', 'description', 'verifiableCredential'],
    properties: {
      id: { type: 'string' },
      description: { type: 'string' },
      verifiableCredential: {
        type: 'array',
        items: {
          type: 'object',
          required: ['claim', 'issuer', 'issued'],
          properties: {
            claim: {
              type: 'object',
              required: ['id', 'currentStatus', 'statusReason'],
              properties: {
                id: { type: 'string' },
                currentStatus: { type: 'string' },
                statusReason: { type: 'string' },
              },
            },
            issuer: { type: 'string' },
            issued: { type: 'string' },
            proof: { type: 'object', nullable: true },
          },
        },
      },
      proof: { type: 'object', nullable: true },
    },
  };
  const validate = ajv.compile(validateSchema);
  if (!validate(credentialStatus)) {
    throw ErrorWithLog(
      validate.errors && validate.errors[0]
        ? JSON.stringify(validate.errors[0])
        : 'Validation error'
    );
  }

  for (const status of credentialStatus.verifiableCredential) {
    if (status.claim.id === vc.id) {
      // IDが一致するステータスを取得
      return status.claim.currentStatus;
    }
  }
  return 'Active';
};
