import { DidObject, PresentationDefinition } from '../../DidManager';
import { SiopAuthenticationReq } from './SiopAuthenticationReq';
import { SiopAuthenticationRes } from './SiopAuthenticationRes';

export * from './common';
export * from './SiopAuthenticationReq';
export * from './SiopAuthenticationRes';
export * from './VpToken';

/**
 * Self-Issued OpenID Provider v2<br>
 * プロトコルサポートクラス
 */
export class SelfIssuedOpenidProviderV2 {
  /**
   *
   * @param didObj
   * @param redirectUri
   * @param presentationDefinition
   * @returns
   */
  static createAuthenticationRequest(
    didObj: DidObject,
    clientName: string,
    redirectUri: string,
    presentationDefinition: PresentationDefinition
  ) {
    return SiopAuthenticationReq.create(didObj, clientName, redirectUri, presentationDefinition);
  }

  static parseAuthenticationRequest(param: Record<string, unknown>) {
    return SiopAuthenticationReq.parse(param);
  }

  static parseAuthenticationResponse(param: Record<string, unknown>) {
    return SiopAuthenticationRes.parse(param);
  }
}
