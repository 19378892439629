import { JSONSchemaType } from 'ajv';

import {
  DidJwt,
  DidManager,
  JwtObject,
  VERIFIABLE_CREDENTIAL_TYPE_INDEX,
  VerifiableCredentialJwt,
} from '../../DidManager';
import { ErrorWithLog, ajv } from '../../common/utils';
import { IssuerMetadata } from './IssuerMetadata';
import { ServerMetadataForIssuer } from './common';

/**
 * Credential Response
 */
export class CredentialRes extends IssuerMetadata {
  static validateSchema: JSONSchemaType<{
    format: 'jwt_vc';
    credential: string;
  }> = {
    type: 'object',
    required: ['format', 'credential'],
    properties: {
      format: { type: 'string' },
      credential: { type: 'string' },
    },
  };

  public format: 'jwt_vc';

  public credentialJwt: JwtObject<VerifiableCredentialJwt>;

  constructor(param: {
    format: 'jwt_vc';
    credential: string;
    issuer: string;
    server_metadata?: ServerMetadataForIssuer;
  }) {
    super(param.issuer, param.server_metadata);

    this.format = param.format;
    this.credentialJwt = DidJwt.decodeJws<VerifiableCredentialJwt>(param.credential);
    // @todo typeにVerifiableCredentialが存在することのチェック
  }

  static parse(
    param: Record<string, unknown>,
    issuer: string,
    serverMetadata: ServerMetadataForIssuer | undefined = undefined
  ) {
    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }

    return new CredentialRes({
      ...param,
      issuer,
      server_metadata: serverMetadata,
    });
  }

  async verifySign(didMgr: DidManager) {
    return DidJwt.verifyJwsByDid(this.credentialJwt, didMgr);
  }

  /**
   * クレデンシャルメタデータの取得
   * サーバーメタデータからクレデンシャルタイプのクレデンシャルメタデータを抽出して返却
   */
  async getCredentialMetadata() {
    const serverMetadata = await this.getServerMetadata();
    const credentialType =
      this.credentialJwt.payload.vc.type[VERIFIABLE_CREDENTIAL_TYPE_INDEX.PRIMARY_TYPE];
    return serverMetadata.credentials_supported[credentialType];
  }
}

export default CredentialRes;
