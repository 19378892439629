import { JSONSchemaType } from 'ajv';
import axios from 'axios';
import dayjs from 'dayjs';
import urljoin from 'url-join';

import { ErrorWithLog, ajv } from '../common/utils';
import { DidJwt, JwtObject } from './DidJwt';
import { DidObject } from './DidObject';
import { VerifiableCredentialJwt } from './VerifiableCredential';

/**
 * DID Configuration Resource 定義<br>
 * https://identity.foundation/.well-known/resources/did-configuration/#did-configuration-resource
 */
export type DidConfigurationResource = {
  '@context': string;
  linked_dids: string[];
};

/**
 * リンクドメイン情報
 */
export class LinkedDomain {
  public origin: string;

  public didConfigurationResource: DidConfigurationResource;

  private linkedDidObjList: {
    domainLinkageCredentialJwt: JwtObject<VerifiableCredentialJwt>;
    isLinked: boolean;
  }[];

  constructor(origin: string, didConfigurationResource: DidConfigurationResource) {
    this.origin = origin;
    this.didConfigurationResource = didConfigurationResource;

    // linkedDidをオブジェクトとして格納
    this.linkedDidObjList = [];
    for (const linkedDid of didConfigurationResource.linked_dids) {
      this.linkedDidObjList.push({
        domainLinkageCredentialJwt: DidJwt.decodeJws<VerifiableCredentialJwt>(linkedDid),
        isLinked: false,
      });
    }
  }

  /**
   * DID Configuration Resource JSONSchemaType
   */
  static jstDidConfigurationResource: JSONSchemaType<DidConfigurationResource> = {
    type: 'object',
    required: ['@context', 'linked_dids'],
    properties: {
      '@context': { type: 'string', format: 'uri' },
      linked_dids: {
        type: 'array',
        items: {
          type: 'string',
          format: 'jwt',
        },
      },
    },
  };

  /**
   * Well Known DID Configurationを取得
   * @param origin 例) https://xxx.issuer.com
   * @returns DID Configuration Resource
   */
  static async getDidConfiguration(origin: string) {
    const url = urljoin(origin, '.well-known/did-configuration.json');

    // ステータスを取得(通信)
    const didConfigurationRes = await axios.get(url);
    if (didConfigurationRes.status !== 200) {
      throw ErrorWithLog(didConfigurationRes.statusText);
    }
    const didConfigurationResource = didConfigurationRes.data;

    const validate = ajv.compile(this.jstDidConfigurationResource);
    if (!validate(didConfigurationResource)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }
    return didConfigurationResource;
  }

  /**
   * 対象OriginのWell Known DID Configurationからリンクドメイン情報を取得
   * @param origin 例) https://xxx.issuer.com
   * @returns リンクドメイン情報
   */
  static async get(origin: string) {
    return new this(origin, await this.getDidConfiguration(origin));
  }

  /**
   * LinkedDid(Domain Linkage Credential)を生成
   * @param origin 例) https://xxx.issuer.com
   * @param didObj
   * @returns
   */
  static generateLinkedDid(origin: string, didObj: DidObject) {
    const issuanceDate = dayjs();
    const expirationDate = issuanceDate.add(15, 'year');

    // JWT(JWS)生成
    return DidJwt.signJws<VerifiableCredentialJwt>(
      DidJwt.createHeader('ES256K', didObj.kid),
      {
        vc: {
          '@context': [
            'https://www.w3.org/2018/credentials/v1',
            'https://identity.foundation/.well-known/contexts/did-configuration-v0.0.jsonld',
          ],
          issuer: didObj.did,
          issuanceDate: issuanceDate.format(),
          expirationDate: expirationDate.format(),
          type: ['VerifiableCredential', 'DomainLinkageCredential'],
          credentialSubject: {
            id: didObj.did,
            origin,
          },
        },
        sub: didObj.did,
        iss: didObj.did,
        nbf: issuanceDate.unix(),
        exp: expirationDate.unix(),
      },
      didObj.keys.signing.private
    );
  }
}
