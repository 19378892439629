import { ServerMetadataForIssuer, getServerMetadataForIssuer } from './common';

/**
 * Issuerメタデータ
 */
export class IssuerMetadata {
  /** Issuer */
  public issuer: string;

  /** サーバーメタデータ */
  protected serverMetadata?: ServerMetadataForIssuer;

  constructor(issuer: string, serverMetadata: ServerMetadataForIssuer | undefined = undefined) {
    this.issuer = issuer;
    this.serverMetadata = serverMetadata;
  }

  /**
   * サーバーメタデータの取得
   * @returns
   */
  async getServerMetadata() {
    if (!this.serverMetadata) {
      this.serverMetadata = await getServerMetadataForIssuer(this.issuer);
    }
    return this.serverMetadata;
  }
}

export default IssuerMetadata;
