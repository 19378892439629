import { Container, Typography } from '@mui/material';
import { Log, SelfIssuedOpenidProviderV2, SiopAuthenticationReq, selectLocale } from 'did-sdk';
import queryString from 'query-string';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { showVcCard } from '../components/functions';
import { Activity } from '../helpers/activity';
import { setupContextSet, useContextSet } from '../helpers/common';
import { VcObject } from '../helpers/vc';

const STATUS = {
  INIT: 0,
  START: 1,
  SELECT_VC: 10,
  PRESENTED_VP: 11,
};

export function PageSiop() {
  const { nowLoadingContext, settingsContext, didContext } = useContextSet();
  const navigate = useNavigate();
  const query = queryString.parse(useLocation().search);
  const [status, setStatus] = useState(STATUS.INIT);
  const [presentaion, setPresentaion] = useState<{
    authenticationReq: SiopAuthenticationReq;
    vcList: VcObject[];
    selectIndex?: number;
  }>();

  useLayoutEffect(() => {
    if (status === STATUS.INIT) {
      nowLoadingContext.setNowLoading(true);
    }
  });

  const init = async () => {
    setupContextSet(settingsContext, didContext).then(() => {
      if (!didContext.didManage.didObj) {
        // DID未発行
        return navigate('/');
      }
      return setStatus(STATUS.START);
    });
  };

  const selectVc = async (index: number) => {
    Log.debug('Selected VC:', index);

    if (!presentaion || !didContext.didManage.didObj) {
      return navigate('/');
    }

    // VP提示
    presentaion.authenticationReq.response(
      didContext.didManage.didObj,
      presentaion.vcList[index].vc.jws
    );
    // アクティビティの保存
    Activity.presentedVp(
      presentaion.authenticationReq.jwt.payload.registration.client_name,
      selectLocale(presentaion.vcList[index].credential.display, 'ja').name
    );

    setPresentaion({ ...presentaion, selectIndex: index });
    return setStatus(STATUS.PRESENTED_VP);
  };

  const main = async () => {
    if (!didContext.didManage.didObj || !didContext.didManage.didMgr) {
      return navigate('/');
    }

    let authenticationReq;
    try {
      // リクエスト解析
      authenticationReq = await SelfIssuedOpenidProviderV2.parseAuthenticationRequest(query);
    } catch (e) {
      // 開発用
      // eslint-disable-next-line no-alert
      return alert(e instanceof Error ? e.message : '');
    }
    Log.debug('authenticationReq:', authenticationReq);

    // 署名チェック
    if (!(await authenticationReq.verifySign(didContext.didManage.didMgr))) {
      throw Error('credentialRes: verifySign NG');
    }

    // 条件を元に対象VCを抽出
    // @todo 暫定
    const vcList: VcObject[] = [];
    for (const vc of await VcObject.all()) {
      if (
        vc.vc.payload.vc.type.includes(
          authenticationReq.jwt.payload.claims.vp_token.presentation_definition.input_descriptors[0]
            .constraints.fields.filter.pattern
        )
      ) {
        vcList.push(vc);
      }
    }
    setPresentaion({ authenticationReq, vcList });
    setStatus(STATUS.SELECT_VC);
    return nowLoadingContext.setNowLoading(false);
  };

  useEffect(() => {
    Log.debug('STATUS:', status);
    switch (status) {
      case STATUS.INIT:
        init();
        break;
      case STATUS.START:
        main();
        break;
      default:
        break;
    }
    // 契機はstatusの変更時のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (status === STATUS.SELECT_VC && presentaion) {
    const items = [];
    for (let cnt = 0; cnt < presentaion.vcList.length; cnt += 1) {
      items.push(
        <div
          key={`ac_${cnt}`}
          style={{ marginTop: '1rem', cursor: 'pointer' }}
          onClick={() => selectVc(cnt)}
        >
          {showVcCard(presentaion.vcList[cnt])}
        </div>
      );
    }

    return (
      <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
        <Typography variant="h5" sx={{ marginBottom: '16px' }}>
          資格情報の提示
        </Typography>
        <Typography sx={{ marginBottom: '1rem' }}>
          {presentaion.authenticationReq.jwt.payload.registration.client_name} からの提示要求。
          <br />
          下記より提示する資格情報を選択してください。
        </Typography>
        {items}
      </Container>
    );
  }
  if (status === STATUS.PRESENTED_VP && presentaion && presentaion.selectIndex !== undefined) {
    return (
      <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
        <Typography variant="h5" sx={{ marginBottom: '16px' }}>
          資格情報の提示
        </Typography>
        <Typography sx={{ marginBottom: '1rem' }}>下記資格証明を提示しました。</Typography>
        {showVcCard(presentaion.vcList[presentaion.selectIndex])}
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        資格情報の提示
      </Typography>
    </Container>
  );
}

export default PageSiop;
