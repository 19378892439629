import { Create as IconCreate } from '@mui/icons-material';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { Log } from 'did-sdk';
import { useEffect, useLayoutEffect, useState } from 'react';
import * as React from 'react';

import { setupContextSet, useContextSet } from '../helpers/common';
import { DidTool } from '../helpers/didTool';
import { PrivateKeyTool } from '../helpers/privateKeyTool';
import { VcObject } from '../helpers/vc';

const STATUS = {
  INIT: 0,
  START: 1,
};

export function PageTop() {
  const { nowLoadingContext, settingsContext, didContext } = useContextSet();
  const [status, setStatus] = useState(STATUS.INIT);
  const [openDidCreated, setOpenDidCreated] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [textDialog, setTextDialog] = useState({ title: '', text: '' });

  useLayoutEffect(() => {
    if (status === STATUS.INIT) {
      nowLoadingContext.setNowLoading(true);
    }
  });

  const init = async () => {
    setupContextSet(settingsContext, didContext).then(() => {
      setStatus(STATUS.START);
    });
  };

  const main = async () => {
    nowLoadingContext.setNowLoading(false);
  };

  useEffect(() => {
    Log.debug('STATUS:', status);
    switch (status) {
      case STATUS.INIT:
        init();
        break;
      case STATUS.START:
        main();
        break;
      default:
        break;
    }
    // 契機はstatusの変更時のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleCloseDidCreated = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenDidCreated(false);
  };

  const registerDid = async () => {
    if (!settingsContext.settings || !didContext.didManage.didMgr) {
      return;
    }
    nowLoadingContext.setNowLoading(true);

    // DID発行
    try {
      const didObject = await didContext.didManage.didMgr.createDid({
        signingKeyId: 'sign-primary-key',
      });

      // 発行した各種情報を保存
      await PrivateKeyTool.save(didObject.signingKeyId, didObject.keys.signing.private);
      if (didObject.keys.recovery) {
        await PrivateKeyTool.save(
          PrivateKeyTool.RESERVE_ID.RECOVERY,
          didObject.keys.recovery.private
        );
      }
      if (didObject.keys.update) {
        await PrivateKeyTool.save(PrivateKeyTool.RESERVE_ID.UPDATE, didObject.keys.update.private);
      }

      // DID Modelを保存
      didContext.didManage.didObj = DidTool.createByDidObject(didObject);
      await DidTool.save(didContext.didManage.didObj);

      // コンテキストにも反映
      didContext.setDidManage(didContext.didManage);

      setOpenDidCreated(true);
    } catch (e) {
      Log.error(e);
      // 開発用
      // eslint-disable-next-line no-alert
      alert('DIDの発行に失敗しました。');
    }

    setTimeout(() => {
      nowLoadingContext.setNowLoading(false);
    }, 300);
  };

  const resolveDid = async (did: string) => {
    if (!settingsContext.settings || !didContext.didManage.didMgr || !didContext.didManage.didObj) {
      return;
    }

    nowLoadingContext.setNowLoading(true);

    let resolveResponse = '';
    try {
      const didDoc = await didContext.didManage.didMgr.resolveDid(did);
      resolveResponse = JSON.stringify(didDoc, null, 2);
      if (!didContext.didManage.didObj.published) {
        if (didDoc.didDocumentMetadata.method.published) {
          // publishedの更新
          didContext.didManage.didObj.published = true;
          didContext.setDidManage(didContext.didManage);
          await DidTool.save(didContext.didManage.didObj);
        }
      }
    } catch {
      resolveResponse = 'DID Not found';
    }

    setTimeout(() => {
      setTextDialog({
        title: 'DID検証レスポンス',
        text: resolveResponse,
      });
      setOpenDialog(true);
      nowLoadingContext.setNowLoading(false);
    }, 500);
  };

  const resolveDidShort = async () => {
    if (!didContext.didManage.didObj) {
      return;
    }
    await resolveDid(didContext.didManage.didObj.didShort);
  };

  const resolveDidLong = async () => {
    if (!didContext.didManage.didObj) {
      return;
    }
    await resolveDid(didContext.didManage.didObj.didLong);
  };

  const deleteDid = async () => {
    nowLoadingContext.setNowLoading(true);

    // 発行した各種情報を削除
    await VcObject.clear();
    await PrivateKeyTool.clear();
    await DidTool.clear();

    // コンテキストにも反映
    didContext.didManage.didObj = null;
    didContext.setDidManage(didContext.didManage);

    setTimeout(() => {
      nowLoadingContext.setNowLoading(false);
    }, 300);
  };

  const closeDialog = async () => {
    setOpenDialog(false);
  };

  if (!didContext.didManage.didObj) {
    return (
      <>
        <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
          <Typography variant="h5" sx={{ marginBottom: '16px' }}>
            DID
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              DIDが発行されていません。
            </Grid>
            <Grid container item xs={12} justifyContent="center">
              <Button
                variant="contained"
                size="large"
                startIcon={<IconCreate />}
                onClick={registerDid}
              >
                DID発行
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Snackbar open={openDidCreated} autoHideDuration={6000} onClose={handleCloseDidCreated}>
          <Alert onClose={handleCloseDidCreated} severity="success" sx={{ width: '100%' }}>
            DIDを発行しました。
          </Alert>
        </Snackbar>
      </>
    );
  }

  const published = didContext.didManage.didObj.published ? (
    <Chip label="公開済" color="success" />
  ) : (
    <Chip label="未公開" color="warning" />
  );
  return (
    <>
      <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
        <Typography variant="h5" sx={{ marginBottom: '16px' }}>
          DID
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <TextField
                  label="DID"
                  fullWidth
                  multiline
                  value={didContext.didManage.didObj.didShort}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Container fixed sx={{ marginTop: '8px', textAlign: 'right' }}>
                  {published}
                </Container>
              </CardContent>
              <Divider sx={{ marginX: '8px' }} />
              <CardActions>
                <Button size="small" onClick={resolveDidShort}>
                  DIDを検証
                </Button>
                <Button size="small" onClick={resolveDidLong}>
                  DID(Long)を検証
                </Button>
                <Button size="small" color="error" onClick={deleteDid}>
                  削除
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={openDidCreated} autoHideDuration={6000} onClose={handleCloseDidCreated}>
        <Alert onClose={handleCloseDidCreated} severity="success" sx={{ width: '100%' }}>
          DIDを発行しました。
        </Alert>
      </Snackbar>
      <Dialog fullWidth maxWidth="sm" open={openDialog} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{textDialog.title}</DialogTitle>
        <DialogContent>
          <TextField
            label="DID"
            fullWidth
            multiline
            maxRows={16}
            value={textDialog.text}
            InputProps={{
              readOnly: true,
              sx: { fontSize: '11px' },
            }}
            sx={{ marginTop: '8px' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PageTop;
