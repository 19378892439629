import { JSONSchemaType } from 'ajv';

/**
 * Claim Format Designations<br>
 * 本来はldp形式(ldp_vc/ldp_vp)にも対応しているが、一旦jwtのみ対応
 */
export type ClaimFormatDesignations = {
  jwt_vc?: {
    alg: 'ES256K'[];
  };
  jwt_vp?: {
    alg: 'ES256K'[];
  };
};

/**
 * Claim Format Designations (JSONSchemaType)<br>
 * 本来はldp形式(ldp_vc/ldp_vp)にも対応しているが、一旦jwtのみ対応
 */
export const jstClaimFormatDesignations: JSONSchemaType<ClaimFormatDesignations> = {
  type: 'object',
  properties: {
    jwt_vc: {
      type: 'object',
      nullable: true,
      required: ['alg'],
      properties: {
        alg: {
          type: 'array',
          items: {
            type: 'string',
            pattern: '^(ES256K)$',
          },
        },
      },
    },
    jwt_vp: {
      type: 'object',
      nullable: true,
      required: ['alg'],
      properties: {
        alg: {
          type: 'array',
          items: {
            type: 'string',
            pattern: '^(ES256K)$',
          },
        },
      },
    },
  },
};

/**
 * Presentation Definition<br>
 * https://identity.foundation/presentation-exchange/spec/v1.0.0/#presentation-definition
 */
export type PresentationDefinition = {
  id: string;
  name?: string;
  purpose?: string;
  input_descriptors: {
    id: string;
    format: ClaimFormatDesignations;
    constraints: {
      fields: {
        path: string[];
        filter: {
          type: 'string';
          pattern: string;
        };
      };
    };
  }[];
};

/**
 * Presentation Definition (JSONSchemaType)<br>
 * https://identity.foundation/presentation-exchange/spec/v1.0.0/#presentation-definition
 */
export const jstPresentationDefinition: JSONSchemaType<PresentationDefinition> = {
  type: 'object',
  required: ['id', 'input_descriptors'],
  properties: {
    id: { type: 'string' },
    name: { type: 'string', nullable: true },
    purpose: { type: 'string', nullable: true },
    input_descriptors: {
      type: 'array',
      items: {
        type: 'object',
        required: ['id', 'format', 'constraints'],
        properties: {
          id: { type: 'string' },
          format: jstClaimFormatDesignations,
          constraints: {
            type: 'object',
            required: ['fields'],
            properties: {
              fields: {
                type: 'object',
                required: ['path', 'filter'],
                properties: {
                  path: {
                    type: 'array',
                    items: {
                      type: 'string',
                    },
                  },
                  filter: {
                    type: 'object',
                    required: ['type', 'pattern'],
                    properties: {
                      type: { type: 'string' },
                      pattern: { type: 'string' },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};
