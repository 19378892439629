import { JSONSchemaType } from 'ajv';
import axios from 'axios';

import { ErrorWithLog, ajv } from '../../common/utils';
import { IssuerMetadata } from './IssuerMetadata';
import { TokenRes } from './TokenRes';

/**
 * Authorization Response
 */
export class AuthorizationRes extends IssuerMetadata {
  static validateSchema: JSONSchemaType<{
    code: string;
  }> = {
    type: 'object',
    required: ['code'],
    properties: {
      code: { type: 'string', format: 'half-string' },
    },
  };

  public redirectUri: string;

  public codeVerifier: string;

  public code: string;

  constructor(param: { issuer: string; redirectUri: string; codeVerifier: string; code: string }) {
    super(param.issuer);

    this.redirectUri = param.redirectUri;
    this.codeVerifier = param.codeVerifier;
    this.code = param.code;
  }

  static parse(
    param: Record<string, unknown>,
    authInfo: { issuer: string; redirectUri: string; codeVerifier: string }
  ) {
    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }
    return new AuthorizationRes({
      ...authInfo,
      ...param,
    });
  }

  async requestToken() {
    const serverMetadata = await this.getServerMetadata();

    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', this.code);
    params.append('code_verifier', this.codeVerifier);
    params.append('redirect_uri', this.redirectUri);

    // トークンリクエスト
    const tokenRes = await axios.post(serverMetadata.token_endpoint, params);
    if (tokenRes.status !== 200) {
      throw ErrorWithLog(tokenRes.statusText);
    }

    // トークンレスポンスを解析して返却
    return TokenRes.parse(tokenRes.data, this.issuer, this.serverMetadata);
  }
}

export default AuthorizationRes;
