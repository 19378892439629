import { Secp256kPublicJwk } from './DidKey';

/**
 * DID Document
 * @todo 暫定：Serviceなど未定義
 */
export type DidDocument = {
  '@context': string | string[];
  didDocument: {
    id: string;
    verificationMethod: {
      id: string;
      controller: string;
      type: string;
      publicKeyJwk: Secp256kPublicJwk;
    }[];
  };
  didDocumentMetadata: {
    method: {
      published: boolean;
      recoveryCommitment?: string;
      updateCommitment?: string;
    };
    equivalentId?: string[];
  };
};

/**
 * DID Documentから指定verificationMethodIdのPublic Jwkを取得
 * @param didDoc
 * @param verificationMethodId
 * @returns JWK
 */
export const getPublicJwkFromDidDoc = (didDoc: DidDocument, verificationMethodId: string) => {
  // DID DocumentからpublicJwkを取得
  for (const vm of didDoc.didDocument.verificationMethod) {
    if (vm.id === `#${verificationMethodId}`) {
      return vm.publicKeyJwk;
    }
  }
  return undefined;
};
