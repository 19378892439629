import { AccordionDetails, Container, Grid, TextField, Typography } from '@mui/material';
import { Log, getCurrentCredentialStatus2017, selectLocale } from 'did-sdk';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Accordion, AccordionSummary } from '../components/accordion';
import { showVcCard } from '../components/functions';
import { formatDayjs, setupContextSet, useContextSet } from '../helpers/common';
import { VcObject } from '../helpers/vc';

const STATUS = {
  INIT: 0,
  START: 1,
  STATUS_LOAD: 10,
  RUNNING: 99,
};

export function PageVc() {
  const { nowLoadingContext, settingsContext, didContext } = useContextSet();
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.INIT);
  const [vcList, setVcList] = useState([] as VcObject[]);
  const [vcStatusList, setVcStatusList] = useState([] as string[]);

  useLayoutEffect(() => {
    if (status === STATUS.INIT) {
      nowLoadingContext.setNowLoading(true);
    }
  });

  const init = async () => {
    setupContextSet(settingsContext, didContext).then(() => {
      if (!didContext.didManage.didObj) {
        // DID未発行
        return navigate('/');
      }
      return setStatus(STATUS.START);
    });
  };

  const main = async () => {
    // VCを全件取得
    const vcListAll = await VcObject.all();

    // ステータスをセット
    const statusList: string[] = [];
    for (const vc of vcListAll) {
      if (vc.vc.payload.vc.credentialStatus) {
        statusList.push('Confirming');
      } else {
        statusList.push('Active');
      }
    }

    setVcList(vcListAll);
    setVcStatusList(statusList);
    setStatus(STATUS.STATUS_LOAD);

    nowLoadingContext.setNowLoading(false);
  };

  const statusLoad = async () => {
    for (let cnt = 0; cnt < vcStatusList.length; cnt += 1) {
      if (vcStatusList[cnt] === 'Confirming') {
        // Confirmingのものはステータスを確認
        try {
          // eslint-disable-next-line no-await-in-loop
          vcStatusList[cnt] = await getCurrentCredentialStatus2017(vcList[cnt].vc.payload.vc);
        } catch {
          //
        }
      }
    }

    setStatus(STATUS.RUNNING);
  };

  useEffect(() => {
    Log.debug('STATUS:', status);
    switch (status) {
      case STATUS.INIT:
        init();
        break;
      case STATUS.START:
        main();
        break;
      case STATUS.STATUS_LOAD:
        statusLoad();
        break;
      default:
        break;
    }
    // 契機はstatusの変更時のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const items = [];
  for (let cnt = 0; cnt < vcList.length; cnt += 1) {
    items.push(
      <div key={`ac_${cnt}`} style={{ marginTop: '1rem' }}>
        {showVcCard(vcList[cnt])}
        <div style={{ border: 'solid 1px #ccc', marginTop: '4px' }}>
          <Grid container spacing={1} margin={0} fontSize="0.9rem">
            <Grid item xs={12} sm={6}>
              発行元：
              {selectLocale(vcList[cnt].serverMetadata.credential_issuer.display, 'ja').name}
            </Grid>
            <Grid item xs={12} sm={6}>
              発行日時：
              {formatDayjs(vcList[cnt].vc.payload.vc.issuanceDate)}
            </Grid>
            <Grid item xs={12} sm={6}>
              ステータス：{vcStatusList[cnt]}
            </Grid>
          </Grid>
          <Accordion sx={{ marginTop: '4px' }}>
            <AccordionSummary>詳細</AccordionSummary>
            <AccordionDetails>
              <TextField
                label="JSON"
                fullWidth
                multiline
                maxRows={8}
                value={JSON.stringify(vcList[cnt].vc.payload, null, 2)}
                InputProps={{
                  readOnly: true,
                  sx: { fontSize: '12px' },
                }}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        資格情報
      </Typography>
      {items}
    </Container>
  );
}

export default PageVc;
