import { JSONSchemaType } from 'ajv';

import { DidJwt, DidManager, JwtObject, jstJwtPayload } from '../../DidManager';
import { ErrorWithLog, ajv } from '../../common/utils';
import { VpTokenJwt } from './common';

/**
 * @todo 未使用
 */
export class VpToken {
  static validateSchema: JSONSchemaType<VpTokenJwt> = {
    type: 'object',
    required: [...jstJwtPayload.required, 'vp', 'nonce'],
    properties: {
      ...jstJwtPayload.properties,
      vp: {
        type: 'object',
        required: ['@context', 'type', 'verifiableCredential'],
        properties: {
          '@context': { type: 'array', items: { type: 'string' } },
          type: { type: 'array', items: { type: 'string' } },
          verifiableCredential: { type: 'array', items: { type: 'string' } },
        },
      },
    },
  };

  public jwt: JwtObject<VpTokenJwt>;

  constructor(jwt: JwtObject<VpTokenJwt>) {
    this.jwt = jwt;
  }

  static parse(jws: string) {
    const jwt = DidJwt.decodeJws<VpTokenJwt>(jws);
    const param = jwt.payload;
    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }
    return new this(jwt);
  }

  async verifySign(didMgr: DidManager) {
    return DidJwt.verifyJwsByDid(this.jwt, didMgr);
  }
}

export default VpToken;
