import base64url from 'base64url';
import { BinaryLike, createHash } from 'crypto';
import { nanoid } from 'nanoid';

export class DidUtil {
  static hashSha256Base64url(text: BinaryLike) {
    const sha256 = createHash('sha256');
    const hash = sha256.update(text).digest();
    return base64url.encode(hash);
  }

  static hashSha256Base64(text: BinaryLike) {
    return base64url.toBase64(DidUtil.hashSha256Base64url(text));
  }

  static randomString(size: number | undefined = undefined) {
    return nanoid(size);
  }
}

export default DidUtil;
