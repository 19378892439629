import { JSONSchemaType } from 'ajv';
import axios from 'axios';
import dayjs from 'dayjs';

import { DidJwt, DidObject, JwtPayload } from '../../DidManager';
import { Log } from '../../common/log';
import { ErrorWithLog, ajv } from '../../common/utils';
import { CredentialRes } from './CredentialRes';
import { IssuerMetadata } from './IssuerMetadata';
import { ServerMetadataForIssuer } from './common';

export type TokenResParam = {
  access_token: string;
  token_type: 'bearer';
  expires_in: number;
  c_nonce?: string;
  c_nonce_expires_in?: number;
};

export type RequestCredentialJwt = JwtPayload & {
  iss: string;
  nonce?: string;
};

/**
 * Token Response
 */
export class TokenRes extends IssuerMetadata {
  static validateSchema: JSONSchemaType<TokenResParam> = {
    type: 'object',
    required: ['access_token', 'token_type', 'expires_in'],
    properties: {
      access_token: { type: 'string' },
      token_type: { type: 'string' },
      expires_in: { type: 'integer' },
      c_nonce: { type: 'string', nullable: true },
      c_nonce_expires_in: { type: 'integer', nullable: true },
    },
  };

  public accessToken: string;

  public tokenType: 'bearer';

  public expiresIn: number;

  public cNonce: string | undefined;

  public cNonceExpiresIn: number | undefined;

  constructor(
    param: TokenResParam & {
      issuer: string;
      server_metadata?: ServerMetadataForIssuer;
    }
  ) {
    super(param.issuer, param.server_metadata);

    this.accessToken = param.access_token;
    this.tokenType = param.token_type;
    this.expiresIn = param.expires_in;
    this.cNonce = param.c_nonce;
    this.cNonceExpiresIn = param.c_nonce_expires_in;
  }

  static parse(
    param: Record<string, unknown>,
    issuer: string,
    serverMetadata: ServerMetadataForIssuer | undefined = undefined
  ) {
    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }
    return new TokenRes({ ...param, issuer, server_metadata: serverMetadata });
  }

  async requestCredential(clientId: string, credentialType: string, didObj: DidObject) {
    const serverMetadata = await this.getServerMetadata();

    // JWT(JWS)生成
    const jwt = DidJwt.signJws<RequestCredentialJwt>(
      DidJwt.createHeader('ES256K', didObj.kid),
      {
        iss: clientId,
        aud: this.issuer,
        iat: dayjs().format(),
        nonce: this.cNonce,
      },
      didObj.keys.signing.private
    );
    Log.debug('requestCredential jwt:', jwt);

    const params = new URLSearchParams();
    params.append('type', credentialType);
    params.append('format', 'jwt_vc');
    params.append(
      'proof',
      JSON.stringify({
        proof_type: 'jwt',
        jwt: jwt.jws,
      })
    );

    // クレデンシャルクエスト
    const credentialRes = await axios.post(serverMetadata.credential_endpoint, params, {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
    if (credentialRes.status !== 200) {
      throw ErrorWithLog(credentialRes.statusText);
    }

    // クレデンシャルレスポンスを解析して返却
    return CredentialRes.parse(credentialRes.data, this.issuer, this.serverMetadata);
  }
}

export default TokenRes;
