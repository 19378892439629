/* eslint-disable @typescript-eslint/no-unused-vars */
import { applyMiddleware, createStore } from 'redux';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';

import { dexieDb } from '../dexie';
// eslint-disable-next-line import/no-cycle
import ServiceManager from '../helpers/ServiceManager';
import rootReducer from './reducer';

const TIMEOUT = 40000;
const version = 1;

export type RootState = ReturnType<typeof rootReducer>;

const MigratedStorage = {
  async getItem(key: string) {
    try {
      let res;
      const kv = await dexieDb.kv.get(key);
      if (kv) {
        res = kv.value;
      }
      return res;
    } catch {
      // Fail silently
    }

    return null;
  },

  async setItem(key: string, value: string) {
    const result = await dexieDb.kv.put({ key, value });
    return result;
  },

  async removeItem(key: string) {
    await dexieDb.kv.delete(key);
  },
};

/**
 * Transform middleware that blacklists fields from redux persist that we deem too large for persisted storage
 */
const persistTransform = createTransform(
  (inboundState: any) => {
    const { ...controllers } = inboundState.stateMap || {};
    // Reconstruct data to persist
    const newState = {
      stateMap: {
        ...controllers,
      },
    };
    return newState;
  },
  null,
  { whitelist: ['services'] }
);

const persistUserTransform = createTransform(
  (inboundState: any) => {
    const { ...state } = inboundState;
    return state;
  },
  null,
  { whitelist: ['user'] }
);

const persistConfig = {
  key: 'root',
  version,
  blacklist: ['navigation', 'addressbook', 'deeplink'],
  storage: MigratedStorage,
  transforms: [persistTransform, persistUserTransform],
  stateReconciler: autoMergeLevel2,
  timeout: TIMEOUT,
  writeFailHandler: (_error: any) => {
    // 何もしない
  },
};

const pReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);
const composedEnhancer = applyMiddleware(thunk);
export const store = createStore(pReducer, composedEnhancer);

/**
 * Initialize services after persist is completed
 */
const onPersistComplete = () => {
  ServiceManager.getDefault().initialize(store);
};

export const { dispatch } = store;

export const persistor = persistStore(store, null, onPersistComplete);

export type AppDispatch = typeof dispatch;
