import { createContext, useCallback, useState } from 'react';

import { Settings } from '../helpers/settings';

export type SettingsContextType = {
  settings: Settings | null;
  setSettings: (settings: Settings) => void;
};

export const SettingsContext = createContext<SettingsContextType>({} as SettingsContextType);

export const useSettingsContext = (): SettingsContextType => {
  const [settings, setSettings] = useState<Settings | null>(null);
  return {
    settings,
    setSettings: useCallback((current: Settings): void => {
      setSettings(current);
    }, []),
  };
};
