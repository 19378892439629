/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import {
  AbstractManager,
  AccountService,
  GlobalConfig,
  PreferencesService
} from 'nft-core-sdk';

// eslint-disable-next-line import/no-cycle
import AccountAdapter from '../adapters/AccountAdapter';
// eslint-disable-next-line import/no-cycle, import/no-named-as-default
import PreferencesAdapter from '../adapters/PreferencesAdapter';

/**
 * Manage services and Adapters
 */
class ServiceManager extends AbstractManager {
  // eslint-disable-next-line no-use-before-define
  static instance: ServiceManager;

  /**
   * A singleton method，Get the ServiceManager instance
   * @method getDefault
   * @return ServiceManager
   */
  static getDefault(): ServiceManager {
    if (!ServiceManager.instance) {
      ServiceManager.instance = new ServiceManager();
      // Object.freeze(ServiceManager.instance);
    }
    return ServiceManager.instance;
  }

  /**
   * Wallet private key
   * It is only stored in memory for easy use by the payment module
   */
  private _privateKey: string | undefined;

  /**
   * Wallet pwd
   */
  private _pwd: string | undefined;

  /**
   * Setting global parameters
   */
  globalConfigure(): GlobalConfig {
    return {};
  }

  static getAccountService: () => AccountService<AccountAdapter> | undefined = () =>
    ServiceManager.getDefault().getServiceByName('AccountService');

  static getPreferencesService: () => PreferencesService<PreferencesAdapter> | undefined = () =>
    ServiceManager.getDefault().getServiceByName('PreferencesService');

  /**
   *
   * @method initialize
   * @param store
   */
  initialize(store: any): void {
    const reduxState = store.getState?.();
    const stateMap = reduxState?.services?.stateMap;
    this.bind<AccountService<AccountAdapter>, AccountAdapter>(
      new AccountService(),
      new AccountAdapter({ ...stateMap?.AccountService })
    );
    this.bind<PreferencesService<PreferencesAdapter>, PreferencesAdapter>(
      new PreferencesService(),
      new PreferencesAdapter({ ...stateMap?.PreferencesService })
    );
  }

  /**
   * @method resetState
   */
  resetState(): void {
    ServiceManager.getAccountService()?.updateState(
      ServiceManager.getAccountService()?.defaultState
    );
    ServiceManager.getPreferencesService()?.updateState(
      ServiceManager.getPreferencesService()?.defaultState
    );
  }

  /**
   * Create the wallet, restore the wallet, change an account requires the privateKey refresh
   * @method refreshPrivateKey
   */
  async refreshPrivateKey(params: {
    password?: string;
    privateKey?: string;
    selectedAddress: string;
  }): Promise<void> {
    if (params.privateKey) {
      this._privateKey = params.privateKey;
    } else if (params.selectedAddress) {
      if (params.password) {
        this._pwd = params.password;
      }
      if (this._pwd && params.selectedAddress) {
        const pk = await ServiceManager.getAccountService()?.exportAccount(
          this._pwd,
          params.selectedAddress
        );
        this._privateKey = pk;
      }
    }
  }

  /**
   * Gets the privateKey in memory
   * @method privateKey
   */
  get privateKey(): string | undefined {
    return this._privateKey;
  }
}

export default ServiceManager;
