import { Button, Card, CardContent, Container, TextField, Typography } from '@mui/material';
import { Log } from 'did-sdk';
import React, { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';

import { setupContextSet, useContextSet } from '../helpers/common';

const STATUS = {
  INIT: 0,
  START: 1,
  QR_READED: 2,
};

export function PageQr() {
  const { nowLoadingContext, settingsContext, didContext } = useContextSet();
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.INIT);
  const [qrText, setQrText] = useState('');
  const [inputText, setInputText] = useState('');

  useLayoutEffect(() => {
    if (status === STATUS.INIT) {
      nowLoadingContext.setNowLoading(true);
    }
  });

  const init = async () => {
    setupContextSet(settingsContext, didContext).then(() => {
      if (!didContext.didManage.didObj) {
        // DID未発行
        return navigate('/');
      }
      return setStatus(STATUS.START);
    });
  };

  const main = async () => {
    nowLoadingContext.setNowLoading(false);
  };

  useEffect(() => {
    Log.debug('STATUS:', status);
    switch (status) {
      case STATUS.INIT:
        init();
        break;
      case STATUS.START:
        main();
        break;
      default:
        break;
    }
    // 契機はstatusの変更時のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.id) {
      case 'input-text':
        setInputText(() => e.target.value);
        break;
      default:
        break;
    }
  };

  const redirectUrl = async (requestUrl: string) => {
    if (requestUrl.indexOf('http') === 0) {
      window.location.href = requestUrl;
    }
  };

  const manualInput = async () => {
    redirectUrl(inputText);
  };

  const qr = (
    <QrReader
      constraints={{ facingMode: 'environment' }}
      onResult={(result) => {
        if (result) {
          Log.log(result);
          setQrText(result.getText());
          setStatus(STATUS.QR_READED);
          redirectUrl(result.getText());
        }
      }}
    />
  );

  const qrResult = (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6">QR読み取り結果</Typography>
        <Typography sx={{ wordWrap: 'break-word', marginTop: '16px' }}>{qrText}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        QR読み取り
      </Typography>
      <Container maxWidth="sm">{status === STATUS.QR_READED ? qrResult : qr}</Container>
      <Container maxWidth="sm" sx={{ marginTop: '16px' }}>
        <TextField
          id="input-text"
          label="URL(https://)"
          fullWidth
          multiline
          maxRows={6}
          size="small"
          value={inputText}
          onChange={handleChange}
        />
        <Button variant="contained" sx={{ marginTop: '8px' }} onClick={manualInput}>
          手入力
        </Button>
      </Container>
    </Container>
  );
}

export default PageQr;
