import keyto from '@trust/keyto';
import base64url from 'base64url';
import crypto from 'crypto';
import { ecdsaSign, ecdsaVerify, publicKeyConvert, signatureNormalize } from 'secp256k1';

/**
 * Secp256k 公開鍵(JWK)
 */
export type Secp256kPublicJwk = {
  kty: string;
  crv: string;
  x: string;
  y: string;
  kid?: string;
};

/**
 * Secp256k 秘密鍵(JWK)
 */
export type Secp256kPrivateJwk = Secp256kPublicJwk & {
  d: string;
};

const privateJwkToHexString = (jwk: Secp256kPrivateJwk) =>
  keyto
    .from(
      {
        ...jwk,
        crv: 'K-256', // 'secp256k1' → 'K-256' の指定に上書き
      },
      'jwk'
    )
    .toString('blk', 'private');

const publicJwkToHexString = (jwk: Secp256kPublicJwk) => {
  const uncompressed = keyto
    .from(
      {
        ...jwk,
        crv: 'K-256', // 'secp256k1' → 'K-256' の指定に上書き
      },
      'jwk'
    )
    .toString('blk', 'public');

  // Compressed public key
  const compressed = publicKeyConvert(Buffer.from(uncompressed, 'hex'), true);
  return Buffer.from(compressed).toString('hex');
};

/**
 * 秘密鍵(JWK)→UInt8Arrayへの変換
 * @param jwk 秘密鍵(JWK)
 * @returns
 */
export const privateJwkToUInt8Array = (jwk: Secp256kPrivateJwk) => {
  const privateKeyHex = privateJwkToHexString(jwk);
  let asBuffer = Buffer.from(privateKeyHex, 'hex');
  let padding = 32 - asBuffer.length;
  while (padding > 0) {
    asBuffer = Buffer.concat([Buffer.from('00', 'hex'), asBuffer]);
    padding -= 1;
  }
  return Uint8Array.from(asBuffer);
};

/**
 * 公開鍵(JWK)→UInt8Arrayへの変換
 * @param jwk 公開鍵(JWK)
 * @returns
 */
export const publicJwkToUInt8Array = (jwk: Secp256kPublicJwk) => {
  const publicKeyHex = publicJwkToHexString(jwk);
  let asBuffer = Buffer.from(publicKeyHex, 'hex');
  let padding = 32 - asBuffer.length;
  while (padding > 0) {
    asBuffer = Buffer.concat([Buffer.from('00', 'hex'), asBuffer]);
    padding -= 1;
  }
  return Uint8Array.from(asBuffer);
};

/**
 * ES256K(secp256k1)
 */
export class ES256K {
  /**
   * 署名
   * @param message
   * @param privateJwk
   * @returns
   */
  static sign(message: crypto.BinaryLike, privateJwk: Secp256kPrivateJwk) {
    const digest = crypto.createHash('sha256').update(message).digest();
    const signObj = ecdsaSign(digest, privateJwkToUInt8Array(privateJwk));
    return Buffer.from(signObj.signature);
  }

  /**
   * 検証
   * @param jws
   * @param publicJwk
   * @returns
   */
  static verify(jws: string, publicJwk: Secp256kPublicJwk) {
    const [encodedHeader, encodedPayload, encodedSignature] = jws.split('.');
    const message = Buffer.from(`${encodedHeader}.${encodedPayload}`);
    const digest = crypto.createHash('sha256').update(message).digest();

    return ecdsaVerify(
      signatureNormalize(base64url.toBuffer(encodedSignature)),
      digest,
      publicJwkToUInt8Array(publicJwk)
    );
  }
}
