import base64url from 'base64url';

import { Secp256kPrivateJwk, Secp256kPublicJwk } from './DidKey';

/**
 * 秘密鍵＆公開鍵のペア
 */
export type DidKeyPair = {
  public: Secp256kPublicJwk;
  private: Secp256kPrivateJwk;
};

/**
 * DIDの情報オブジェクト
 */
export class DidObject {
  public scheme: string;

  public method: string;

  public didSuffix: string;

  public longFormSuffixData: string;

  public signingKeyId: string;

  public published: boolean;

  public keys: {
    signing: DidKeyPair;
    update?: DidKeyPair;
    recovery?: DidKeyPair;
  };

  /**
   * did = scheme:method:didSuffix:longFormSuffixData
   */
  constructor(
    scheme: string,
    method: string,
    didSuffix: string,
    longFormSuffixData: string,
    signingKeyId: string,
    published: boolean,
    keys: {
      signing: DidKeyPair;
      update?: DidKeyPair;
      recovery?: DidKeyPair;
    }
  ) {
    this.scheme = scheme;
    this.method = method;
    this.didSuffix = didSuffix;
    this.longFormSuffixData = longFormSuffixData;
    this.signingKeyId = signingKeyId;
    this.published = published;
    this.keys = keys;
  }

  /**
   * DID文字列を各パーツに分解
   * @param didString DID文字列(Short or Long)
   * @returns
   */
  static parseDid(didString: string) {
    const didParts = didString.split(':');
    const scheme = didParts[0];
    let longFormSuffixData = '';

    let lastIndex = didParts.length - 1;
    if (lastIndex > 2) {
      try {
        // JSON形式かチェック
        JSON.parse(base64url.decode(didParts[lastIndex]));
        // JSON形式であれば、DidLongとして処理
        longFormSuffixData = didParts[lastIndex];
        lastIndex -= 1;
      } catch {
        //
      }
    }
    const didSuffix = didParts[lastIndex];
    const method = didParts.slice(1, lastIndex).join(':');

    return {
      scheme,
      method,
      didSuffix,
      longFormSuffixData,
    };
  }

  /**
   * DID文字列と鍵情報からDIDオブジェクトを生成
   * @param didString DID文字列
   * @param signingKeyId 署名鍵ID
   * @param published 公開状態
   * @param keys 鍵情報
   * @returns
   */
  static createByDidString(
    didString: string,
    signingKeyId: string,
    published: boolean,
    keys: {
      signing: DidKeyPair;
      update?: DidKeyPair;
      recovery?: DidKeyPair;
    }
  ) {
    const { scheme, method, didSuffix, longFormSuffixData } = DidObject.parseDid(didString);
    return new DidObject(
      scheme,
      method,
      didSuffix,
      longFormSuffixData,
      signingKeyId,
      published,
      keys
    );
  }

  /**
   * JSON文字列からDIDオブジェクトを生成
   * @todo JSON項目のチェックが必要
   * @param jsonString JSON文字列
   * @returns
   */
  static createByJsonString(jsonString: string) {
    const { scheme, method, didSuffix, longFormSuffixData, signingKeyId, published, keys } =
      JSON.parse(jsonString);
    return new DidObject(
      scheme,
      method,
      didSuffix,
      longFormSuffixData,
      signingKeyId,
      published,
      keys
    );
  }

  /**
   * DID<br>
   * published=trueならDID(Short)、falseならDID(Long)が返却されます
   */
  get did() {
    return this.published ? this.didShort : this.didLong;
  }

  /**
   * DID(Short)
   */
  get didShort() {
    return [this.scheme, this.method, this.didSuffix].join(':');
  }

  /**
   * DID(Long)
   */
  get didLong() {
    return [this.scheme, this.method, this.didSuffix, this.longFormSuffixData].join(':');
  }

  /**
   * DID#signingKeyId
   */
  get kid() {
    return `${this.did}#${this.signingKeyId}`;
  }
}

export default DidObject;
