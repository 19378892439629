import { Secp256kPrivateJwk } from 'did-sdk';

import { dexieDb } from '../dexie';

export class PrivateKeyTool {
  static RESERVE_ID = {
    RECOVERY: '@RECOVERY',
    UPDATE: '@UPDATE',
  };

  static async save(id: string, privateKey: Secp256kPrivateJwk) {
    await dexieDb.privatekey.put({ id, privateKey });
  }

  static async load(id: string) {
    return dexieDb.privatekey.get(id);
  }

  static async clear() {
    await dexieDb.privatekey.clear();
  }
}

export default PrivateKeyTool;
