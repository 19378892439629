import { ArrowForwardIosSharp as IconArrowForwardIosSharp } from '@mui/icons-material';
import {
  AccordionProps,
  AccordionSummaryProps,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from '@mui/material';
import React from 'react';

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<IconArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  fontSize: '0.8rem',
  minHeight: '0',
  padding: '0 8px',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: '4px',
  },
}));
