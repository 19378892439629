import { AuthorizationReq } from './AuthorizationReq';
import { AuthorizationRes } from './AuthorizationRes';
import { CredentialReq } from './CredentialReq';
import { IssuanceInitReq } from './IssuanceInitReq';
import { TokenReq } from './TokenReq';
import { ServerMetadataForIssuer } from './common';

export * from './common';
export * from './AuthorizationReq';
export * from './AuthorizationRes';
export * from './CredentialReq';
export * from './CredentialRes';
export * from './IssuanceInitReq';
export * from './IssuerMetadata';
export * from './TokenReq';
export * from './TokenRes';

/**
 * OpenID for Verifiable Credential Issuance<br>
 * プロトコルサポートクラス
 */
export class OpenidVcIssuance {
  /**
   * Issuer向けサーバーメタデータの作成
   * @param metadata
   * @returns
   */
  static createServerMetadataForIssuer(metadata: ServerMetadataForIssuer) {
    return metadata;
  }

  /**
   * 発行開始リクエストの解析
   * @param param リクエストパラメータのオブジェクト
   * @returns 発行開始リクエスト
   */
  static parseIssuanceInitRequest(param: Record<string, unknown>) {
    return IssuanceInitReq.parse(param);
  }

  /**
   * 認可リクエストの解析
   * @param param リクエストパラメータのオブジェクト
   * @returns
   */
  static parseAuthorizationRequest(param: Record<string, unknown>) {
    return AuthorizationReq.parse(param);
  }

  /**
   * 認可レスポンスの解析
   * @param param レスポンスパラメータのオブジェクト
   * @param authInfo 認可リクエスト時の情報
   * @param authInfo.issuer Issuer
   * @param authInfo.redirectUri リダイレクトURI
   * @param authInfo.codeVerifier 検証用コード
   * @returns
   */
  static parseAuthorizationResponse(
    param: Record<string, unknown>,
    authInfo: {
      issuer: string;
      redirectUri: string;
      codeVerifier: string;
    }
  ) {
    return AuthorizationRes.parse(param, authInfo);
  }

  /**
   * トークンリクエストの解析
   * @param param リクエストパラメータのオブジェクト
   * @returns
   */
  static parseTokenRequest(param: Record<string, unknown>) {
    return TokenReq.parse(param);
  }

  /**
   * クレデンシャルリクエストの解析
   * @param param リクエストパラメータのオブジェクト
   * @returns
   */
  static parseCredentialRequest(param: Record<string, unknown>) {
    return CredentialReq.parse(param);
  }
}
