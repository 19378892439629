import { JSONSchemaType } from 'ajv';
import base64url from 'base64url';

import { Log } from '../common/log';
import { ErrorWithLog } from '../common/utils';
import { getPublicJwkFromDidDoc } from './DidDocument';
import { ES256K, Secp256kPrivateJwk, Secp256kPublicJwk } from './DidKey';
import { DidManager } from './DidManager';

export type JwtAlg = 'ES256K';
export type JwtTyp = 'JWT';

/**
 * JWT Header 定義
 */
export type JwtHeader = {
  alg: JwtAlg;
  typ: JwtTyp;
  kid: string;
};

/**
 * JWT Payload 定義
 */
export type JwtPayload = {
  iss?: string;
  sub?: string;
  jti?: string;
  iat?: number | string;
  exp?: number | string;
  nbf?: number | string;
  aud?: string;
};

/**
 * JWT Payload JSONSchemaType
 */
export const jstJwtPayload: JSONSchemaType<JwtPayload> = {
  type: 'object',
  required: [],
  properties: {
    iss: { type: 'string', nullable: true },
    sub: { type: 'string', nullable: true },
    jti: { type: 'string', nullable: true },
    iat: { type: ['string', 'integer'], nullable: true },
    exp: { type: ['string', 'integer'], nullable: true },
    nbf: { type: ['string', 'integer'], nullable: true },
    aud: { type: 'string', nullable: true },
  },
};

/**
 * JWT Object 定義
 */
export type JwtObject<T extends JwtPayload = Record<string, unknown> & JwtPayload> = {
  header: JwtHeader;
  payload: T;
  jws: string;
};

/**
 * DID向けJWTサポートクラス
 */
export class DidJwt {
  /**
   * JWT Headerの作成
   * @param alg
   * @param kid
   * @returns
   */
  static createHeader(alg: JwtAlg, kid: string): JwtHeader {
    return { alg, typ: 'JWT', kid };
  }

  /**
   * 秘密鍵で署名し、JWSを作成
   * @param header
   * @param payload
   * @param privateJwk
   * @returns
   */
  static signJws<T extends JwtPayload = Record<string, unknown> & JwtPayload>(
    header: JwtHeader,
    payload: T,
    privateJwk: Secp256kPrivateJwk
  ): JwtObject<T> {
    const encodedHeader = base64url.encode(JSON.stringify(header));
    const encodedPayload = base64url.encode(JSON.stringify(payload));
    const message = Buffer.from(`${encodedHeader}.${encodedPayload}`);

    let encodedSignature;
    switch (header.alg) {
      case 'ES256K':
        encodedSignature = base64url.encode(ES256K.sign(message, privateJwk));
        break;
      default:
        throw ErrorWithLog(`Unsupported alg: ${header.alg}`);
    }

    return {
      header,
      payload,
      jws: `${encodedHeader}.${encodedPayload}.${encodedSignature}`,
    };
  }

  /**
   * JWS文字列からJWTにデコード
   * @param jwsString
   * @returns
   */
  static decodeJws<T extends JwtPayload = Record<string, unknown> & JwtPayload>(
    jwsString: string
  ): JwtObject<T> {
    const jwsParse = jwsString.split('.');
    return {
      header: JSON.parse(base64url.decode(jwsParse[0])),
      payload: JSON.parse(base64url.decode(jwsParse[1])),
      jws: jwsString,
    };
  }

  /**
   * JWSを検証(公開鍵指定)
   * @param jwt
   * @param publicJwk
   * @returns
   */
  static verifyJws(jwt: JwtObject, publicJwk: Secp256kPublicJwk) {
    try {
      switch (jwt.header.alg) {
        case 'ES256K':
          return ES256K.verify(jwt.jws, publicJwk);
        default:
          throw ErrorWithLog(`Unsupported alg: ${jwt.header.alg}`);
      }
    } catch (e) {
      return false;
    }
  }

  /**
   * JWSを検証(公開鍵をDIDドキュメントから取得)
   * @param jwt
   * @param didMgr
   * @returns
   */
  static async verifyJwsByDid(jwt: JwtObject, didMgr: DidManager) {
    // kidからDIDとverificationMethodのidを取得
    const [did, vid] = jwt.header.kid.split('#');

    // DID Documet取得
    const didDoc = await didMgr.resolveDid(did);
    Log.debug('resolveDid:', didDoc);

    // DID Documetから公開鍵取得
    const publicJwk = getPublicJwkFromDidDoc(didDoc, vid);
    if (!publicJwk) {
      return false;
    }

    // JWT署名チェック
    if (!DidJwt.verifyJws(jwt, publicJwk)) {
      return false;
    }
    Log.debug('JWT verify is OK');
    return true;
  }
}
