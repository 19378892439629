import { IonServiceModel } from '@decentralized-identity/ion-sdk';

import { Log } from '../common/log';
import { ErrorWithLog } from '../common/utils';
import { DidDocument } from './DidDocument';
import { DidObject } from './DidObject';

/**
 * DID生成インターフェース
 */
export interface IDidCreater {
  get key(): string;
  create(signingKeyId: string, services?: IonServiceModel[]): Promise<DidObject>;
}

/**
 * DID解決インターフェース
 */
export interface IDidResolver {
  get key(): string;
  resolve(did: string): Promise<DidDocument>;
}

/**
 * DIDの各種操作を提供する
 */
export class DidManager {
  private didCreaterMap: Record<string, IDidCreater>;

  private didCreaterDefaultKey: string;

  private didResolverMap: Record<string, IDidResolver>;

  /**
   *
   * @param createrList 登録するDID生成インターフェースのリスト
   * @param resolverList 登録するDID生成インターフェースのリスト
   */
  constructor(createrList: IDidCreater[], resolverList: IDidResolver[]) {
    // CreaterMapの作成
    this.didCreaterMap = {};
    this.didCreaterDefaultKey = createrList ? createrList[0].key : '';
    for (const didCreater of createrList) {
      this.didCreaterMap[didCreater.key] = didCreater;
    }

    // ResolverMapの作成
    this.didResolverMap = {};
    for (const didResolver of resolverList) {
      this.didResolverMap[didResolver.key] = didResolver;
    }
  }

  /**
   * 登録されているDID生成インターフェースのKeyの一覧を返却する
   * @returns
   */
  getCreaterList() {
    return Object.keys(this.didCreaterMap);
  }

  /**
   * デフォルトで使用するDID生成インターフェースを設定する
   * @param key
   * @returns true:成功 / false:失敗
   */
  setCreaterDefault(key: string) {
    if (!this.getCreaterList().includes(key)) {
      return false;
    }
    this.didCreaterDefaultKey = key;
    return true;
  }

  /**
   * デフォルトで使用するDID生成インターフェースを取得する
   * @returns
   */
  getCreaterDefault() {
    return this.didCreaterDefaultKey;
  }

  /**
   * DIDの作成
   * @param __namedParameters.signingKeyId 署名鍵ID
   * @param __namedParameters.services サービス情報
   * @param __namedParameters.key IDidCreater登録キー
   * @returns DIDオブジェクト
   */
  async createDid({
    signingKeyId,
    services,
    key,
  }: {
    signingKeyId: string;
    services?: IonServiceModel[];
    key?: string;
  }) {
    if (!key) {
      // key未指定の場合はdefaultを実行
      if (!this.didCreaterDefaultKey) {
        throw ErrorWithLog('Not found DidCreater');
      }
      Log.debug('createDid:', this.didCreaterDefaultKey);
      return this.didCreaterMap[this.didCreaterDefaultKey].create(signingKeyId, services);
    }
    if (key in this.didCreaterMap) {
      Log.debug('createDid:', key);
      return this.didCreaterMap[key].create(signingKeyId, services);
    }
    throw ErrorWithLog(`Not found in DidCreater: ${key}`);
  }

  /**
   * DIDの作成(LinkedDomainsあり)<br>
   * Issuer/Verifier向け
   * @param __namedParameters.signingKeyId 署名鍵ID
   * @param __namedParameters.domain ドメイン(https://xxx.com/ 形式)
   * @param __namedParameters.key? IDidCreater登録キー
   * @returns DIDオブジェクト
   */
  async createDidLinkedDomains({
    signingKeyId,
    domain,
    key,
  }: {
    signingKeyId: string;
    domain: string;
    key?: string;
  }) {
    const url = new URL(domain);
    Log.debug(url);
    return this.createDid({
      signingKeyId,
      key,
      services: [
        {
          id: 'linkeddomains',
          type: 'LinkedDomains',
          serviceEndpoint: {
            origin: [url.origin],
          },
        },
      ],
    });
  }

  /**
   * DID解決
   * @param did DID(Short or Long)
   * @returns DIDドキュメント
   */
  async resolveDid(did: string) {
    const { method } = DidObject.parseDid(did);
    if (method in this.didResolverMap) {
      Log.debug('resolveDid:', this.didResolverMap[method].key);
      return this.didResolverMap[method].resolve(did);
    }
    throw ErrorWithLog(`Not found in DidResolver: ${method}`);
  }
}

export default DidManager;
