import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { SWUpdateDialog } from './ServiceWorkerUpdateDialog';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const rootElement = document.getElementById('root');
if (rootElement) {
  createRoot(rootElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      const swUpDlgElement = document.querySelector('.SW-update-dialog');
      if (swUpDlgElement) {
        createRoot(swUpDlgElement).render(<SWUpdateDialog registration={registration} />);
      }
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
