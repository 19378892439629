import { JSONSchemaType } from 'ajv';

import { DidUtil } from '../../DidUtil';
import { ErrorWithLog, ajv } from '../../common/utils';
import { IssuerMetadata } from './IssuerMetadata';

/**
 * 発行開始リクエスト
 */
export class IssuanceInitReq extends IssuerMetadata {
  static validateSchema: JSONSchemaType<{
    issuer: string;
    credential_type: string;
    op_state?: string;
  }> = {
    type: 'object',
    required: ['issuer', 'credential_type'],
    properties: {
      issuer: { type: 'string', format: 'uri' },
      credential_type: { type: 'string', format: 'uri' },
      op_state: { type: 'string', format: 'half-string', nullable: true },
    },
  };

  /** クレデンシャルタイプ */
  public credentialType: string;

  /** OPステート */
  public opState: string | undefined;

  constructor(param: { issuer: string; credential_type: string; op_state?: string }) {
    super(param.issuer);

    this.credentialType = param.credential_type;
    this.opState = param.op_state;
  }

  /**
   * 発行開始リクエストの解析
   * @param param リクエストパラメータのオブジェクト
   * @returns 発行開始リクエスト
   */
  static parse(param: Record<string, unknown>) {
    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }
    return new IssuanceInitReq(param);
  }

  /**
   * 認可リダイレクトを生成
   * @param clientId クライアントID
   * @param redirectUri リダイレクトURI
   * @returns 認可リダイレクトURLとcodeVerifier
   */
  async generateAuthorizationRedirect(clientId: string, redirectUri: string) {
    const serverMetadata = await this.getServerMetadata();
    const authRedirectUrl = new URL(serverMetadata.authorization_endpoint);
    const codeVerifier = DidUtil.randomString(43);

    authRedirectUrl.searchParams.append('response_type', 'code');
    authRedirectUrl.searchParams.append('client_id', clientId);
    authRedirectUrl.searchParams.append(
      'code_challenge',
      DidUtil.hashSha256Base64url(codeVerifier)
    );
    authRedirectUrl.searchParams.append('code_challenge_method', 'S256');
    authRedirectUrl.searchParams.append('redirect_uri', redirectUri);
    authRedirectUrl.searchParams.append(
      'authorization_details',
      JSON.stringify({
        type: 'openid_credential',
        credential_type: this.credentialType,
        format: 'jwt_vc',
      })
    );
    if (this.opState) {
      authRedirectUrl.searchParams.append('op_state', this.opState);
    }

    return { authRedirectUrl, codeVerifier };
  }
}

export default IssuanceInitReq;
