import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AccountState,
  PreferencesState
} from 'nft-core-sdk';

interface ACTION {
  serviceState: any;
  serviceName: string;
}

interface ServiceType {
  stateMap: {
    [serviceName: string]: any;
    PreferencesService?: PreferencesState;
    AccountService?: AccountState;
  };
}

const initialState: ServiceType = {
  stateMap: {
  },
};

const ServicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    updateStateMap: (state: ServiceType, action: PayloadAction<ACTION>) => {
      const {
        payload: { serviceState, serviceName },
      } = action;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.stateMap[serviceName] = serviceState;
      return state;
    },
  },
});

export const ServicesReducer = ServicesSlice.reducer;
export const { updateStateMap } = ServicesSlice.actions;
