import { JSONSchemaType } from 'ajv';

import { DidUtil } from '../../DidUtil';
import { ErrorWithLog, ajv } from '../../common/utils';

/**
 * Token Request
 */
export class TokenReq {
  static validateSchema: JSONSchemaType<{
    grant_type: string;
    code: string;
    code_verifier: string;
    redirect_uri: string;
  }> = {
    type: 'object',
    required: ['grant_type', 'code', 'code_verifier', 'redirect_uri'],
    properties: {
      grant_type: { type: 'string' },
      code: { type: 'string' },
      code_verifier: { type: 'string' },
      redirect_uri: { type: 'string' },
    },
  };

  public grantType: string;

  public code: string;

  public codeVerifier: string;

  public redirectUri: string;

  constructor(param: {
    grant_type: string;
    code: string;
    code_verifier: string;
    redirect_uri: string;
  }) {
    this.grantType = param.grant_type;
    this.code = param.code;
    this.codeVerifier = param.code_verifier;
    this.redirectUri = param.redirect_uri;
  }

  static parse(param: Record<string, unknown>) {
    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }
    return new TokenReq(param);
  }

  /**
   * 検証
   * @todo S256固定
   * @param authInfo
   * @returns
   */
  verify(authInfo: { codeChallenge?: string; codeChallengeMethod?: 'S256'; redirectUri: string }) {
    if (authInfo.codeChallenge) {
      // codeChallengeの指定あり
      if (this.codeVerifier) {
        // codeChallengeのチェック
        if (authInfo.codeChallenge !== DidUtil.hashSha256Base64url(this.codeVerifier)) {
          return false;
        }
      } else {
        // codeVerifierが無い場合はNG
        return false;
      }
    }

    if (authInfo.redirectUri !== this.redirectUri) {
      return false;
    }
    return true;
  }

  /**
   *
   * @todo staticにするか
   * @param res トークンレスポンスのJSONオブジェクト生成
   * @returns
   */
  // eslint-disable-next-line class-methods-use-this
  generateResponse(res: {
    access_token: string;
    expires_in?: number;
    c_nonce?: string;
    c_nonce_expires_in?: number;
  }) {
    return {
      ...res,
      token_type: 'bearer',
    };
  }
}

export default TokenReq;
