import { JSONSchemaType } from 'ajv';
import axios from 'axios';
import dayjs from 'dayjs';

import {
  DidJwt,
  DidManager,
  DidObject,
  JwtObject,
  JwtPayload,
  PresentationDefinition,
  jstJwtPayload,
  jstPresentationDefinition,
} from '../../DidManager';
import { DidUtil } from '../../DidUtil';
import { Log } from '../../common/log';
import { ErrorWithLog, ajv } from '../../common/utils';
import { RpRegistration, jstRpRegistration } from '../OidcCommon';
import { SiopAuthenticationResParam, VpTokenJwt } from './common';

export type SiopAuthenticationReqClaims = {
  vp_token: {
    presentation_definition: PresentationDefinition;
  };
};

/**
 * @todo 暫定的にresponse_typeはvp_tokenのみ対応
 */
export type SiopAuthenticationReqJwt = {
  scope: 'openid';
  response_type: 'vp_token';
  client_id: string;
  redirect_uri: string;
  response_mode: 'post';
  claims: SiopAuthenticationReqClaims;
  registration: RpRegistration;
  nonce: string;
} & JwtPayload;

/**
 * https://openid.net/specs/openid-connect-self-issued-v2-1_0.html#section-10
 */
export class SiopAuthenticationReq {
  static validateSchema: JSONSchemaType<SiopAuthenticationReqJwt> = {
    type: 'object',
    required: [
      ...jstJwtPayload.required,
      'scope',
      'response_type',
      'client_id',
      'redirect_uri',
      'response_mode',
      'claims',
      'nonce',
    ],
    properties: {
      ...jstJwtPayload.properties,
      scope: { type: 'string', pattern: '^(openid)$' },
      response_type: { type: 'string' },
      client_id: { type: 'string' },
      redirect_uri: { type: 'string', format: 'uri-template' },
      response_mode: { type: 'string', pattern: '^(post)$' },
      claims: {
        type: 'object',
        required: ['vp_token'],
        properties: {
          vp_token: {
            type: 'object',
            required: ['presentation_definition'],
            properties: {
              presentation_definition: jstPresentationDefinition,
            },
          },
        },
      },
      registration: jstRpRegistration,
      nonce: { type: 'string' },
    },
  };

  static validateSchemaPre: JSONSchemaType<{ request_uri: string }> = {
    type: 'object',
    required: ['request_uri'],
    properties: {
      request_uri: { type: 'string', format: 'uri-template' },
    },
  };

  public jwt: JwtObject<SiopAuthenticationReqJwt>;

  constructor(jwt: JwtObject<SiopAuthenticationReqJwt>) {
    this.jwt = jwt;
  }

  static create(
    didObj: DidObject,
    cliendName: string,
    redirectUri: string,
    presentationDefinition: PresentationDefinition
  ) {
    // JWT(JWS)生成
    const jwt = DidJwt.signJws<SiopAuthenticationReqJwt>(
      DidJwt.createHeader('ES256K', didObj.kid),
      {
        iat: dayjs().unix(),
        scope: 'openid',
        response_type: 'vp_token',
        client_id: didObj.did,
        redirect_uri: redirectUri,
        response_mode: 'post',
        claims: {
          vp_token: { presentation_definition: presentationDefinition },
        },
        registration: {
          client_name: cliendName,
          subject_syntax_types_supported: ['did:ion'],
        },
        nonce: DidUtil.randomString(),
      },
      didObj.keys.signing.private
    );

    return new SiopAuthenticationReq(jwt);
  }

  static async parse(param: Record<string, unknown>) {
    const validate = ajv.compile(this.validateSchemaPre);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }

    const req = await axios.get(param.request_uri);
    return SiopAuthenticationReq.parseJws(req.data);
  }

  static parseJws(jws: string) {
    const jwt = DidJwt.decodeJws<SiopAuthenticationReqJwt>(jws);
    const param = jwt.payload;

    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }

    return new SiopAuthenticationReq(jwt);
  }

  async verifySign(didMgr: DidManager) {
    return DidJwt.verifyJwsByDid(this.jwt, didMgr);
  }

  async response(didObj: DidObject, vcJws: string) {
    // JWT(JWS)生成
    const jwt = DidJwt.signJws<VpTokenJwt>(
      DidJwt.createHeader('ES256K', didObj.kid),
      {
        vp: {
          '@context': ['https://www.w3.org/2018/credentials/v1'],
          type: ['VerifiablePresentation'],
          verifiableCredential: [vcJws],
        },
        nonce: this.jwt.payload.nonce,
        iat: dayjs().unix(),
      },
      didObj.keys.signing.private
    );
    Log.debug('vp_token:', jwt);

    const jsonBody: SiopAuthenticationResParam = {
      vp_token: jwt.jws,
    };

    const res = await axios.post(this.jwt.payload.redirect_uri, jsonBody);
    if (res.status !== 200) {
      throw ErrorWithLog(res.statusText);
    }

    return jwt;
  }
}

export default SiopAuthenticationReq;
