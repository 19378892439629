/* eslint-disable no-console */
import { dexieDb } from '../dexie';

export class Token {
  public contractList: any;

  static KEYS = {
    SETTING_CONTRACT_LIST:'SETTING_CONTRACT_LIST',
  };

  async load() {
    // indexedDBからLoad
    const contractList = await dexieDb.token.get(Token.KEYS.SETTING_CONTRACT_LIST);
    if (contractList) {
      this.contractList = contractList.value as any;
    }
  }

  async save() {
    // indexedDBにSave
    await dexieDb.token.put({ key: Token.KEYS.SETTING_CONTRACT_LIST, value: this.contractList });
  }

  async clear() {
    await Token.clear();
    await this.load();
  }

  static async load() {
    const token = new Token();
    await token.load();
    return token;
  }

  static async clear() {
    await dexieDb.token.bulkDelete(Object.keys(Token.KEYS));
  }
}

export default Token;
