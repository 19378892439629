import { dexieDb } from '../dexie';

export class Settings {
  public selectCreater: string;

  public urlOperation: string;

  public urlResolve: string;

  static KEYS = {
    SELECT_CREATER: 'SELECT_CREATER',
    URL_OPERATION: 'URL_OPERATION',
    URL_RESOLVE: 'URL_RESOLVE',
  };

  constructor() {
    // デフォルト値
    this.selectCreater = 'ion-with-challenge';
    this.urlOperation = 'https://beta.ion.msidentity.com/api/v1.0/';
    this.urlResolve = 'https://beta.discover.did.microsoft.com/1.0/';
  }

  async load() {
    // indexedDBからLoad
    const selectCreater = await dexieDb.settings.get(Settings.KEYS.SELECT_CREATER);
    if (selectCreater) {
      this.selectCreater = selectCreater.value as string;
    }

    const dbUrlOperation = await dexieDb.settings.get(Settings.KEYS.URL_OPERATION);
    if (dbUrlOperation) {
      this.urlOperation = dbUrlOperation.value as string;
    }

    const dbUrlResolve = await dexieDb.settings.get(Settings.KEYS.URL_RESOLVE);
    if (dbUrlResolve) {
      this.urlResolve = dbUrlResolve.value as string;
    }
  }

  async save() {
    // indexedDBにSave
    await dexieDb.settings.put({ key: Settings.KEYS.SELECT_CREATER, value: this.selectCreater });
    await dexieDb.settings.put({ key: Settings.KEYS.URL_OPERATION, value: this.urlOperation });
    await dexieDb.settings.put({ key: Settings.KEYS.URL_RESOLVE, value: this.urlResolve });
  }

  static async load() {
    const settings = new Settings();
    await settings.load();
    return settings;
  }

  static async clear() {
    await dexieDb.settings.clear();
  }
}

export default Settings;
