import { Save as IconSave } from '@mui/icons-material';
import { Alert, Button, Container, Grid, Snackbar, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { useContextSet } from '../helpers/common';
import { Network } from '../helpers/network';
import { Wallets } from '../helpers/wallet';

/**
 * 設定画面
 * @returns
 */
export function PageNetwork() {
  const { residentContext } = useContextSet();
  const { network } = residentContext;
  const [snackbar, setSnackbar] = useState<string>();
  const [snackbarError, setSnackbarError] = useState<string>();
  const [rpcUrl, setRpcUrl] = useState('');
  const [chainId, setChainId] = useState('');
  const [chainSymbol, setChainSymbol] = useState('');
  const [rpcList, setRpcList] = useState([{ rpcUrl: '', chainId: '', chainSymbol: '' }]);


  useEffect(() => {
    if (network) {
      setRpcUrl(network.rpcUrl);
      setChainId(network.chainId);
      setChainSymbol(network.chainSymbol);
      setRpcList(network.rpcList)
    }
  }, [network]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'rpc-url':
        setRpcUrl(() => event.target.value);
        break;
      case 'chainId':
        setChainId(() => event.target.value);
        break;
      case 'chainSymbol':
        setChainSymbol(() => event.target.value);
        break;
      default:
        break;
    }
  };

  const closeSnackbar = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(undefined);
  };

  const updataNetwork = async () => {
    if (network) {
      const rpcInfo = { rpcUrl: '', chainId: '', chainSymbol: '' };
      network.rpcUrl = rpcUrl;
      network.chainId = chainId;
      network.chainSymbol = chainSymbol;
      rpcInfo.rpcUrl = rpcUrl;
      rpcInfo.chainId = chainId;
      rpcInfo.chainSymbol = chainSymbol;
      const rpcUrls = [{ rpcUrl: '', chainId: '', chainSymbol: '' }]
      rpcUrls.pop()
      rpcList.forEach((item) => {
        if (item.chainId === chainId) {
          rpcUrls.push(rpcInfo)
        }
        if (item.chainId !== chainId) {
          rpcUrls.push(item)

        }
      })
      network.rpcList = rpcUrls;
      await network.save();
      residentContext.setWallet(await Wallets.load(network));
    }
  };

  const saveNetwork = async () => {
    // 添加时使用nriProvider
    const urlObj = new URL(rpcUrl);
    const url = { url: urlObj.pathname ? urlObj.origin + urlObj.pathname : urlObj.origin, user: urlObj.username, password: urlObj.password };
    const nriProvider = new ethers.providers.JsonRpcProvider(url);
    const cId = (await nriProvider.getNetwork()).chainId;
    let flag = true;
    rpcList.forEach((item) => {
      if (item.chainId === chainId) {
        flag = false;
      }
    })
    if (cId.toString() === chainId) {
      if (network && network?.rpcUrl !== rpcUrl && flag) {
        const rpcInfo = { rpcUrl: '', chainId: '', chainSymbol: '' };
        network.rpcUrl = rpcUrl;
        network.chainId = chainId;
        network.chainSymbol = chainSymbol;
        rpcInfo.rpcUrl = rpcUrl;
        rpcInfo.chainId = chainId;
        rpcInfo.chainSymbol = chainSymbol;
        rpcList.push(rpcInfo);
        network.rpcList = rpcList;
        // 保存nriProvider到network对象
        network.nriProvider = nriProvider;
        await network.save();
        residentContext.setWallet(await Wallets.load(network));
        alert('設定を保存しました。');
      }

      if (!flag) {
        updataNetwork()
        alert('設定を保存しました。');
      }
      await network?.load();
      window.location.reload()
    } else {
      alert(`RPC エンドポイントは、別のチェーン ID を持つチェーンを使用しています:${cId}`)

    }
  };

  const resetNetwork = async () => {
    if (network) {
      if (rpcList.length === 1) {
        setSnackbarError('デフォルトnetworkを削除することはできません。');
        return;
      }
      await Network.clear();
      const newNetwork = await Network.load();
      residentContext.setNetwork(newNetwork);
      residentContext.setWallet(await Wallets.load(newNetwork));

      const list = rpcList;
      list.pop();
      if (list.length !== 0) {
        const rpcInfo = list[list.length - 1]
        network.rpcList = list;
        network.rpcUrl = rpcInfo.rpcUrl;
        network.chainId = rpcInfo.chainId;
        network.chainSymbol = rpcInfo.chainSymbol;
        setRpcUrl(rpcInfo.rpcUrl)
        setChainId(rpcInfo.chainId)
        setChainSymbol(rpcInfo.chainSymbol)
        await network.save();
        residentContext.setNetwork(network);
        residentContext.setWallet(await Wallets.load(network));

      }
      alert('設定をリセットしました。');
      window.location.reload()
    }
  };

  const closeSnackbarError = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarError(undefined);
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
        <Typography variant="h5" sx={{ marginBottom: '16px' }}>
          ネットワーク
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="rpc-url"
              fullWidth
              label="RPC URL"
              variant="outlined"
              value={rpcUrl}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="chainId"
              fullWidth
              label="チェーンID"
              variant="outlined"
              value={chainId}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="chainSymbol"
              fullWidth
              label="通貨記号"
              variant="outlined"
              value={chainSymbol}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={8}>
            <Button fullWidth variant="contained" startIcon={<IconSave />} onClick={saveNetwork}>
              保存
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="contained" color="error" onClick={resetNetwork}>
              リセット
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={!!snackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} sx={{ width: '100%' }}>
          {snackbar}
        </Alert>
      </Snackbar>
      <Snackbar open={!!snackbarError} autoHideDuration={6000} onClose={closeSnackbarError}>
        <Alert severity="error" onClose={closeSnackbarError} sx={{ width: '100%' }}>
          {snackbarError}
        </Alert>
      </Snackbar>
    </>
  );
}

export default PageNetwork;
