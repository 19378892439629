import { JSONSchemaType } from 'ajv';

export type RpRegistration = {
  client_name: string;
  subject_syntax_types_supported: string[];
};

export const jstRpRegistration: JSONSchemaType<RpRegistration> = {
  type: 'object',
  required: ['client_name', 'subject_syntax_types_supported'],
  properties: {
    client_name: { type: 'string' },
    subject_syntax_types_supported: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
};
