import { IonDid, IonRequest, IonServiceModel } from '@decentralized-identity/ion-sdk';
import axios from 'axios';

import { Log } from '../../common/log';
import { ErrorWithLog } from '../../common/utils';
import { DidDocument } from '../DidDocument';
import { Secp256kPrivateJwk, Secp256kPublicJwk } from '../DidKey';
import { IDidCreater } from '../DidManager';
import { DidObject } from '../DidObject';
import { createRequestInput, generateIonKeySet } from './IonDidCreaterWithChallenge';

const submitIonRequest = async (solveChallengeUri: string, requestBody: string) => {
  // Createリクエスト
  const createResponse = await axios.post(solveChallengeUri, requestBody, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (createResponse.status !== 200) {
    throw ErrorWithLog(createResponse.statusText);
  }
  Log.debug('createResponse:', createResponse.data);
  return createResponse.data as DidDocument;
};

/**
 * DID Creater(ION Challengeなし版)
 */
export class IonDidCreaterNoChallenge implements IDidCreater {
  // implements Method
  // eslint-disable-next-line class-methods-use-this
  get key() {
    return 'ion-no-challenge';
  }

  public operationsUrl: string;

  /**
   *
   * @param operationsUrl Operations URL
   */
  constructor(operationsUrl: string) {
    this.operationsUrl = operationsUrl;
  }

  /**
   *
   * @param signingKeyId 署名鍵ID
   * @returns DIDオブジェクト
   */
  async create(signingKeyId: string, services?: IonServiceModel[]) {
    // 鍵生成
    const { recoveryKeyPair, updateKeyPair, signingDocKeyPair } = await generateIonKeySet(
      signingKeyId
    );

    // DID作成リクエスト
    const input = createRequestInput(
      signingDocKeyPair[0],
      updateKeyPair[0],
      recoveryKeyPair[0],
      services
    );

    const createRequest = IonRequest.createCreateRequest(input);
    const longFormDid = IonDid.createLongFormDid(input);
    const longFormSuffixData = longFormDid.substring(longFormDid.lastIndexOf(':') + 1);
    Log.debug('createRequest:', createRequest);

    // エンドポイントへリクエスト
    const createResponse = await submitIonRequest(
      this.operationsUrl,
      JSON.stringify(createRequest)
    );

    // DidObjectにして返却
    return DidObject.createByDidString(
      [createResponse.didDocument.id, longFormSuffixData].join(':'),
      signingKeyId,
      createResponse.didDocumentMetadata.method.published,
      {
        signing: {
          public: signingDocKeyPair[0].publicKeyJwk as Secp256kPublicJwk,
          private: signingDocKeyPair[1] as Secp256kPrivateJwk,
        },
        update: {
          public: updateKeyPair[0],
          private: updateKeyPair[1] as Secp256kPrivateJwk,
        },
        recovery: {
          public: recoveryKeyPair[0],
          private: recoveryKeyPair[1] as Secp256kPrivateJwk,
        },
      }
    );
  }
}

export default IonDidCreaterNoChallenge;
