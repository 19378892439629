import { Container, Typography } from '@mui/material';
import { DidUtil, Log, OpenidVcIssuance, selectLocale } from 'did-sdk';
import queryString from 'query-string';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { showVcCard } from '../components/functions';
import { ActivityModel } from '../dexie';
import { Activity } from '../helpers/activity';
import { getCache, setCache } from '../helpers/cache';
import { setupContextSet, useContextSet } from '../helpers/common';
import { VcObject } from '../helpers/vc';

const STATUS = {
  INIT: 0,
  START: 1,
};

type CacheIssuance = {
  key: string;
  issuer: string;
  credentialType: string;
  redirectUri: string;
  codeVerifier: string;
};

export function PageInitiateIssuance() {
  const { nowLoadingContext, settingsContext, didContext } = useContextSet();
  const navigate = useNavigate();
  const query = queryString.parse(useLocation().search);
  const [status, setStatus] = useState(STATUS.INIT);

  useLayoutEffect(() => {
    if (status === STATUS.INIT) {
      nowLoadingContext.setNowLoading(true);
    }
  });

  const init = async () => {
    setupContextSet(settingsContext, didContext).then(() => {
      if (!didContext.didManage.didObj) {
        // DID未発行
        return navigate('/');
      }
      return setStatus(STATUS.START);
    });
  };

  const main = async () => {
    let issuanceInitReq;
    try {
      // リクエスト解析
      issuanceInitReq = OpenidVcIssuance.parseIssuanceInitRequest(query);
    } catch (e) {
      // 開発用
      // eslint-disable-next-line no-alert
      alert(e instanceof Error ? e.message : '');
      return;
    }
    Log.debug('issuanceInitReq:', issuanceInitReq);

    const ot = DidUtil.randomString();
    const redirectUri = `${window.location.origin}/cb_issuance/${ot}/`;

    // 認可リダイレクトURL生成
    // @todo clinetId
    const { authRedirectUrl, codeVerifier } = await issuanceInitReq.generateAuthorizationRedirect(
      'https://wallet.com',
      redirectUri
    );
    Log.debug('AuthorizationRedirect:', authRedirectUrl);

    // キャッシュに保存
    const cacheIssuance: CacheIssuance = {
      key: ot,
      issuer: issuanceInitReq.issuer,
      credentialType: issuanceInitReq.credentialType,
      redirectUri,
      codeVerifier,
    };
    await setCache('ISSUANCE', JSON.stringify(cacheIssuance));

    // @todo 開発用にWait挿入
    setTimeout(() => {
      nowLoadingContext.setNowLoading(false);
      // 認可URLにリダイレクト
      window.location.href = authRedirectUrl.toString();
    }, 1000);
  };

  useEffect(() => {
    Log.debug('STATUS:', status);
    switch (status) {
      case STATUS.INIT:
        init();
        break;
      case STATUS.START:
        main();
        break;
      default:
        break;
    }
    // 契機はstatusの変更時のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        資格情報発行開始要求
      </Typography>
    </Container>
  );
}

type ParamsCallbackIssuance = {
  ot: string;
};

export function PageCallbackIssuance() {
  const { nowLoadingContext, settingsContext, didContext } = useContextSet();
  const navigate = useNavigate();
  const query = queryString.parse(useLocation().search);
  const [status, setStatus] = useState(STATUS.INIT);
  const [receivedVc, setReceivedVc] = useState<{
    vc: VcObject;
    activity: ActivityModel;
  }>();
  const { ot } = useParams<ParamsCallbackIssuance>();

  useLayoutEffect(() => {
    if (status === STATUS.INIT) {
      nowLoadingContext.setNowLoading(true);
    }
  });

  const init = async () => {
    setupContextSet(settingsContext, didContext).then(() => {
      if (!didContext.didManage.didObj) {
        // DID未発行
        return navigate('/');
      }
      return setStatus(STATUS.START);
    });
  };

  const main = async () => {
    if (!didContext.didManage.didObj || !didContext.didManage.didMgr) {
      return navigate('/');
    }

    // キャッシュを取得
    const cache = await getCache('ISSUANCE');
    if (!ot || !cache) {
      return navigate('/');
    }
    const cacheIssuance: CacheIssuance = JSON.parse(cache.value);

    if (cacheIssuance.key !== ot) {
      // キャッシュの整合性チェック
      return navigate('/');
    }

    try {
      // リクエスト解析
      const authorizationRes = OpenidVcIssuance.parseAuthorizationResponse(query, cacheIssuance);
      Log.debug('authorizationRes:', authorizationRes);

      // トークンリクエスト
      const tokenRes = await authorizationRes.requestToken();
      Log.debug('tokenRes:', tokenRes);

      // クレデンシャルリクエスト
      // @todo clientId
      const credentialRes = await tokenRes.requestCredential(
        'https://wallet.com',
        cacheIssuance.credentialType,
        didContext.didManage.didObj
      );
      Log.debug('credentialRes:', credentialRes);

      // 署名チェック
      if (!(await credentialRes.verifySign(didContext.didManage.didMgr))) {
        throw Error('credentialRes: verifySign NG');
      }

      // クレデンシャルメタデータ取得
      const serverMetadata = await tokenRes.getServerMetadata();
      const credentialMetadata = await credentialRes.getCredentialMetadata();

      // VC保存
      const vc = await VcObject.save(
        credentialRes.credentialJwt,
        serverMetadata,
        credentialMetadata
      );
      Log.debug('vcModel:', vc);

      // アクティビティの追加
      const activity = await Activity.receivedVc(
        selectLocale(serverMetadata.credential_issuer.display, 'ja').name,
        selectLocale<{ name: string }>(credentialMetadata.display, 'ja').name
      );

      // セット
      setReceivedVc({ vc, activity });
    } catch (e) {
      // 開発用
      // eslint-disable-next-line no-alert
      alert(e instanceof Error ? e.message : '');
      return navigate('/');
    }

    return nowLoadingContext.setNowLoading(false);
  };

  useEffect(() => {
    Log.debug('STATUS:', status);
    switch (status) {
      case STATUS.INIT:
        init();
        break;
      case STATUS.START:
        main();
        break;
      default:
        break;
    }
    // 契機はstatusの変更時のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (receivedVc) {
    return (
      <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
        <Typography variant="h5" sx={{ marginBottom: '16px' }}>
          資格情報の受け取り
        </Typography>
        {showVcCard(receivedVc.vc)}
        <Typography sx={{ marginTop: '1rem' }}>{receivedVc.activity.content}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        資格情報の受け取り
      </Typography>
    </Container>
  );
}
