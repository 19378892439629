import { JSONSchemaType } from 'ajv';

import { ErrorWithLog, ajv } from '../../common/utils';

/**
 * Authorization Request
 */
export class AuthorizationReq {
  static validateSchema: JSONSchemaType<{
    response_type: string;
    client_id: string;
    code_challenge: string;
    code_challenge_method: 'S256';
    redirect_uri: string;
    authorization_details: string;
    op_state?: string;
  }> = {
    type: 'object',
    required: [
      'response_type',
      'client_id',
      'code_challenge',
      'code_challenge_method',
      'redirect_uri',
      'authorization_details',
    ],
    properties: {
      response_type: { type: 'string', pattern: '^(code)$' },
      client_id: { type: 'string', format: 'half-string' },
      code_challenge: { type: 'string', format: 'half-string', minLength: 43, maxLength: 128 },
      code_challenge_method: { type: 'string', pattern: '^(S256)$' },
      redirect_uri: { type: 'string', format: 'uri' },
      authorization_details: { type: 'string' },
      op_state: { type: 'string', format: 'half-string', nullable: true },
    },
  };

  public responseType: string;

  public clientId: string;

  public codeChallenge: string;

  public codeChallengeMethod: 'S256';

  public redirectUri: string;

  public authorizationDetails: Record<string, unknown>;

  public opState: string | undefined;

  constructor(param: {
    response_type: string;
    client_id: string;
    code_challenge: string;
    code_challenge_method: 'S256';
    redirect_uri: string;
    authorization_details: Record<string, unknown>;
    op_state?: string;
  }) {
    this.responseType = param.response_type;
    this.clientId = param.client_id;
    this.codeChallenge = param.code_challenge;
    this.codeChallengeMethod = param.code_challenge_method;
    this.redirectUri = param.redirect_uri;
    this.authorizationDetails = param.authorization_details;
    this.opState = param.op_state;
  }

  static parse(param: Record<string, unknown>) {
    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }
    return new AuthorizationReq({
      ...param,
      authorization_details: JSON.parse(param.authorization_details),
    });
  }

  /**
   * 認可レスポンスのリダイレクトURL生成
   * @param code 返却するcode値
   * @returns
   */
  generateRedirect(code: string) {
    const redirectUrl = new URL(this.redirectUri);
    redirectUrl.searchParams.append('code', code);
    return redirectUrl;
  }
}

export default AuthorizationReq;
