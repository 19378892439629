/* eslint-disable no-console */
import { ethers } from 'ethers';
import { dexieDb } from '../dexie';

export class Network {

  public rpcUrl: string;

  public chainId: string;

  public chainSymbol: string;

  public rpcList: any;

  public nriProvider: any;

  static KEYS = {
    SELECT_CREATER: 'SELECT_CREATER',
    URL_OPERATION: 'URL_OPERATION',
    URL_RESOLVE: 'URL_RESOLVE',
    SETTING_RPC_URL: 'SETTING_RPC_URL',
    SETTING_CONTRACT_ADDRESS: 'SETTING_CONTRACT_ADDRESS',
    SETTING_RPC_LIST: 'SETTING_RPC_LIST',
    SETTING_RPC_CHAINID: 'SETTING_RPC_CHAINID',
    SETTING_RPC_CHAINSYMBOL: 'SETTING_RPC_CHAINSYMBOL'
  };

  constructor() {
    const rpcurl = 'https://oasys-test.ssird-smartcontract-wallet.com/rpc';
    const chainid = '321';
    const chainsymbol = 'OAS';
    // デフォルト値
    this.rpcUrl = rpcurl;
    this.rpcList = [{ 'rpcUrl': rpcurl, 'chainId': chainid, 'chainSymbol': chainsymbol }];
    this.chainId = chainid;
    this.chainSymbol = chainsymbol;
    // 保存nriProvider
    const urlObj = new URL(rpcurl);
    const url = { url: urlObj.pathname ? urlObj.origin + urlObj.pathname : urlObj.origin, user: urlObj.username, password: urlObj.password };
    const nriProvider = new ethers.providers.JsonRpcProvider(url);
    this.nriProvider = nriProvider;
  }

  async load() {
    // indexedDBからLoad
    const rpcUrl = await dexieDb.network.get(Network.KEYS.SETTING_RPC_URL);
    if (rpcUrl) {
      this.rpcUrl = rpcUrl.value as string;
      // 保存nriProvider
      const urlObj = new URL(rpcUrl.value as string);
      const url = { url: urlObj.pathname ? urlObj.origin + urlObj.pathname : urlObj.origin, user: urlObj.username, password: urlObj.password };
      const nriProvider = new ethers.providers.JsonRpcProvider(url);
      this.nriProvider = nriProvider;
    }

    const rpcList = await dexieDb.network.get(Network.KEYS.SETTING_RPC_LIST);
    if (rpcList) {
      this.rpcList = rpcList.value as any;
    }

    const chainId = await dexieDb.network.get(Network.KEYS.SETTING_RPC_CHAINID);
    if (chainId) {
      this.chainId = chainId.value as string;
    }
    const chainSymbol = await dexieDb.network.get(Network.KEYS.SETTING_RPC_CHAINSYMBOL);
    if (chainSymbol) {
      this.chainSymbol = chainSymbol.value as string;
    }

  }

  async save() {
    // indexedDBにSave
    await dexieDb.network.put({ key: Network.KEYS.SETTING_RPC_URL, value: this.rpcUrl });
    await dexieDb.network.put({ key: Network.KEYS.SETTING_RPC_LIST, value: this.rpcList });
    await dexieDb.network.put({ key: Network.KEYS.SETTING_RPC_CHAINID, value: this.chainId });
    await dexieDb.network.put({ key: Network.KEYS.SETTING_RPC_CHAINSYMBOL, value: this.chainSymbol });
  }

  async clear() {
    await Network.clear();
    await this.load();
  }

  static async load() {
    const network = new Network();
    await network.load();
    return network;
  }

  static async clear() {
    await dexieDb.network.bulkDelete(Object.keys(Network.KEYS));
  }
}

export default Network;
