import { DidObject } from 'did-sdk';

import { dexieDb } from '../dexie';

export class DidTool {
  static async save(didObj: DidObject) {
    await dexieDb.did.put({ ...didObj, id: this.ID });
  }

  static async load() {
    const didModel = await dexieDb.did.get(this.ID);
    if (didModel) {
      // Classとしてnew
      return new DidObject(
        didModel.scheme,
        didModel.method,
        didModel.didSuffix,
        didModel.longFormSuffixData,
        didModel.signingKeyId,
        didModel.published,
        didModel.keys
      );
    }
    return null;
  }

  static async clear() {
    await dexieDb.did.clear();
  }

  static createByDidObject(didObject: DidObject) {
    return new DidObject(
      didObject.scheme,
      didObject.method,
      didObject.didSuffix,
      didObject.longFormSuffixData,
      didObject.signingKeyId,
      didObject.published,
      didObject.keys
    );
  }

  static ID = 'onlyid';
}

export default DidTool;
