import { DidManager, DidObject } from 'did-sdk';
import { createContext, useCallback, useState } from 'react';

type DidManage = {
  didObj: DidObject | null;
  didMgr: DidManager | null;
};

export type DidContextType = {
  didManage: DidManage;
  setDidManage: (didManage: DidManage) => void;
};

export const DidContext = createContext<DidContextType>({} as DidContextType);

export const useDidContext = (): DidContextType => {
  const [didManage, setDidManage] = useState<DidManage>({ didObj: null, didMgr: null });
  return {
    didManage,
    setDidManage: useCallback((current: DidManage): void => {
      setDidManage(current);
    }, []),
  };
};
