import { JSONSchemaType } from 'ajv';
import dayjs from 'dayjs';

import {
  DidJwt,
  DidManager,
  DidObject,
  JwtObject,
  VerifiableCredential,
  VerifiableCredentialJwt,
} from '../../DidManager';
import { ErrorWithLog, ajv } from '../../common/utils';
import { RequestCredentialJwt } from './TokenRes';

/**
 * Credential Request
 */
export class CredentialReq {
  static validateSchema: JSONSchemaType<{
    type: string;
    format?: 'jwt_vc';
    proof: string;
  }> = {
    type: 'object',
    required: ['type'],
    properties: {
      type: { type: 'string' },
      format: { type: 'string', pattern: '^(jwt_vc)$', nullable: true },
      proof: { type: 'string' },
    },
  };

  static parseProof(proofJson: string) {
    const proof = JSON.parse(proofJson);
    const validateSchema: JSONSchemaType<{
      proof_type: 'jwt';
      jwt: string;
    }> = {
      type: 'object',
      required: ['proof_type', 'jwt'],
      properties: {
        proof_type: { type: 'string', pattern: '^(jwt)$' },
        jwt: { type: 'string' },
      },
    };
    const validate = ajv.compile(validateSchema);
    if (!validate(proof)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }
    return {
      proof_type: proof.proof_type,
      jwt: DidJwt.decodeJws<RequestCredentialJwt>(proof.jwt),
    };
  }

  public type: string;

  public format: 'jwt_vc' | undefined;

  public proof: { proof_type: 'jwt'; jwt: JwtObject<RequestCredentialJwt> };

  constructor(param: {
    type: string;
    format?: 'jwt_vc';
    proof: { proof_type: 'jwt'; jwt: JwtObject<RequestCredentialJwt> };
  }) {
    this.type = param.type;
    this.format = param.format;
    this.proof = param.proof;
  }

  static parse(param: Record<string, unknown>) {
    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }

    const { type, format, proof } = param;
    return new CredentialReq({
      type,
      format,
      proof: CredentialReq.parseProof(proof),
    });
  }

  async verifySign(didMgr: DidManager) {
    return DidJwt.verifyJwsByDid(this.proof.jwt, didMgr);
  }

  /**
   *
   * @todo typeのチェック
   * @param vc
   * @param didObj
   * @returns
   */
  // eslint-disable-next-line class-methods-use-this
  generateResponse(vc: VerifiableCredential, didObj: DidObject) {
    // JWT(JWS)生成
    const jwt = DidJwt.signJws<VerifiableCredentialJwt>(
      DidJwt.createHeader('ES256K', didObj.kid),
      {
        vc,
        sub: didObj.did,
        jti: vc.id,
        iss: vc.issuer,
        nbf: dayjs(vc.issuanceDate).unix(),
        iat: dayjs(vc.issuanceDate).unix(),
        exp: dayjs(vc.expirationDate).unix(),
      },
      didObj.keys.signing.private
    );
    return {
      format: 'jwt_vc',
      credential: jwt.jws,
    };
  }
}

export default CredentialReq;
