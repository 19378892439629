import { createContext, useCallback, useState } from 'react';

export type NowLoadingContextType = {
  isNowLoading: boolean;
  setNowLoading: (isNowLoading: boolean) => void;
};

export const NowLoadingContext = createContext<NowLoadingContextType>({} as NowLoadingContextType);

export const useNowLoadingContext = (): NowLoadingContextType => {
  const [isNowLoading, setNowLoading] = useState(false);
  return {
    isNowLoading,
    setNowLoading: useCallback((current: boolean): void => {
      setNowLoading(current);
    }, []),
  };
};
