import axios from 'axios';
import urljoin from 'url-join';

import { Log } from '../../common/log';
import { ErrorWithLog } from '../../common/utils';
import { DidDocument } from '../DidDocument';
import { IDidResolver } from '../DidManager';

/**
 * DID Resolver(ION)
 */
export class IonDidResolver implements IDidResolver {
  // implements Method
  // eslint-disable-next-line class-methods-use-this
  get key() {
    return 'ion';
  }

  public identifiersUrl: string;

  /**
   *
   * @param identifiersUrl Identifiers URL
   */
  constructor(identifiersUrl = 'https://beta.discover.did.microsoft.com/1.0/identifiers') {
    this.identifiersUrl = identifiersUrl;
  }

  async resolve(did: string) {
    const resolveResponse = await axios.get(urljoin(this.identifiersUrl, did));
    if (resolveResponse.status !== 200) {
      throw ErrorWithLog(resolveResponse.statusText);
    }
    Log.debug('resolveResponse:', resolveResponse.data);

    return resolveResponse.data as DidDocument;
  }
}

export default IonDidResolver;
