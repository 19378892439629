import { IonDid, IonServiceModel } from '@decentralized-identity/ion-sdk';

import { Log } from '../../common/log';
import { Secp256kPrivateJwk, Secp256kPublicJwk } from '../DidKey';
import { IDidCreater } from '../DidManager';
import { DidObject } from '../DidObject';
import { createRequestInput, generateIonKeySet } from './IonDidCreaterWithChallenge';

/**
 * DID Creater(ブロックチェーンに書き込まない版)
 */
export class IonDidCreaterNoRegistered implements IDidCreater {
  // implements Method
  // eslint-disable-next-line class-methods-use-this
  get key() {
    return 'ion-no-registered';
  }

  /**
   *
   * @param signingKeyId 署名鍵ID
   * @returns DIDオブジェクト
   */
  // implements Method
  // eslint-disable-next-line class-methods-use-this
  async create(signingKeyId: string, services?: IonServiceModel[]) {
    // 鍵生成
    const { recoveryKeyPair, updateKeyPair, signingDocKeyPair } = await generateIonKeySet(
      signingKeyId
    );

    // DID作成リクエスト
    const input = createRequestInput(
      signingDocKeyPair[0],
      updateKeyPair[0],
      recoveryKeyPair[0],
      services
    );

    const longFormDid = IonDid.createLongFormDid(input);
    Log.debug('longFormDid:', longFormDid);

    // DidObjectにして返却
    return DidObject.createByDidString(longFormDid, signingKeyId, false, {
      signing: {
        public: signingDocKeyPair[0].publicKeyJwk as Secp256kPublicJwk,
        private: signingDocKeyPair[1] as Secp256kPrivateJwk,
      },
      update: {
        public: updateKeyPair[0],
        private: updateKeyPair[1] as Secp256kPrivateJwk,
      },
      recovery: {
        public: recoveryKeyPair[0],
        private: recoveryKeyPair[1] as Secp256kPrivateJwk,
      },
    });
  }
}

export default IonDidCreaterNoRegistered;
