import Ajv from 'ajv';
import addFormats from 'ajv-formats';

import { Log } from './log';

export const ErrorWithLog = (message: string, outlog = Log.warn) => {
  outlog(message);
  return new Error(message);
};

export const ajv = new Ajv({ allowUnionTypes: true });
addFormats(ajv);
ajv.addFormat('half-string', /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/);
ajv.addFormat('jwt', /^([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-+/=]*)$/);
