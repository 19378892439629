import { JSONSchemaType } from 'ajv';

import { DidJwt } from '../../DidManager';
import { ErrorWithLog, ajv } from '../../common/utils';
import { SiopAuthenticationResParam, VpTokenJwt } from './common';

export class SiopAuthenticationRes {
  static validateSchema: JSONSchemaType<SiopAuthenticationResParam> = {
    type: 'object',
    required: ['vp_token'],
    properties: {
      vp_token: {
        type: 'string',
      },
      id_token: {
        type: 'string',
        nullable: true,
      },
      state: {
        type: 'string',
        nullable: true,
      },
    },
  };

  public vpToken: string;

  public idToken?: string;

  public state?: string;

  constructor(param: SiopAuthenticationResParam) {
    this.vpToken = param.vp_token;
    this.idToken = param.id_token;
    this.state = param.state;
  }

  static parse(param: Record<string, unknown>) {
    const validate = ajv.compile(this.validateSchema);
    if (!validate(param)) {
      throw ErrorWithLog(
        validate.errors && validate.errors[0]
          ? JSON.stringify(validate.errors[0])
          : 'Validation error'
      );
    }
    return new this(param);
  }

  getVpTokenJwt() {
    return DidJwt.decodeJws<VpTokenJwt>(this.vpToken);
  }
}

export default SiopAuthenticationRes;
