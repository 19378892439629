import dayjs from 'dayjs';
import {
  DidManager,
  IonDidCreaterNoChallenge,
  IonDidCreaterNoRegistered,
  IonDidCreaterWithChallenge,
  IonDidResolver,
} from 'did-sdk';
import { useContext } from 'react';
import urljoin from 'url-join';

import { DidContext, DidContextType } from '../contexts/DidContext';
import { NowLoadingContext } from '../contexts/NowLoadingContext';
import { ResidentContext } from '../contexts/ResidentContext';
import { SettingsContext, SettingsContextType } from '../contexts/SettingsContext';
import { DidTool } from './didTool';
import { Settings } from './settings';

/**
 * 共通コンテキストのセット
 * @returns
 */
export const useContextSet = () => ({
  nowLoadingContext: useContext(NowLoadingContext),
  settingsContext: useContext(SettingsContext),
  didContext: useContext(DidContext),
  residentContext: useContext(ResidentContext),
});

/**
 * 共通コンテキストのセットアップ
 * @param settingsContext
 * @param didContext
 * @returns
 */
export const setupContextSet = async (
  settingsContext: SettingsContextType,
  didContext: DidContextType,
  forced = false
) => {
  if (settingsContext.settings && !forced) {
    return;
  }

  // 設定の読み込み
  const settings = await Settings.load();
  settingsContext.setSettings(settings);

  // DID Managerのセット
  didContext.didManage.didMgr = new DidManager(
    [
      new IonDidCreaterWithChallenge(
        urljoin(settings.urlOperation, 'operations'),
        urljoin(settings.urlOperation, 'proof-of-work-challenge')
      ),
      new IonDidCreaterNoRegistered(),
      new IonDidCreaterNoChallenge(urljoin(settings.urlOperation, 'operations')),
    ],
    [new IonDidResolver(urljoin(settings.urlResolve, 'identifiers'))]
  );
  didContext.didManage.didMgr.setCreaterDefault(settings.selectCreater);

  // DIDの読み込み
  didContext.didManage.didObj = await DidTool.load();

  // DID Manageのセット
  didContext.setDidManage(didContext.didManage);
};

export const formatDayjs = (date: string | number | Date | dayjs.Dayjs) =>
  dayjs(date).format('YYYY-MM-DD HH:mm:ss');
