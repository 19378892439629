import { Container, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { Log } from 'did-sdk';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ActivityModel } from '../dexie';
import { Activity } from '../helpers/activity';
import { formatDayjs, setupContextSet, useContextSet } from '../helpers/common';

const STATUS = {
  INIT: 0,
  START: 1,
};

export function PageActivity() {
  const { nowLoadingContext, settingsContext, didContext } = useContextSet();
  const navigate = useNavigate();
  const [status, setStatus] = useState(STATUS.INIT);
  const [activityList, setActivityList] = useState([] as ActivityModel[]);

  useLayoutEffect(() => {
    if (status === STATUS.INIT) {
      nowLoadingContext.setNowLoading(true);
    }
  });

  const init = async () => {
    setupContextSet(settingsContext, didContext).then(() => {
      if (!didContext.didManage.didObj) {
        // DID未発行
        return navigate('/');
      }
      return setStatus(STATUS.START);
    });
  };

  const main = async () => {
    // アクティビティを全件取得
    setActivityList(await Activity.all());

    nowLoadingContext.setNowLoading(false);
  };

  useEffect(() => {
    Log.debug('STATUS:', status);
    switch (status) {
      case STATUS.INIT:
        init();
        break;
      case STATUS.START:
        main();
        break;
      default:
        break;
    }
    // 契機はstatusの変更時のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const items = [];
  for (let cnt = 0; cnt < activityList.length; cnt += 1) {
    items.push(
      <TableRow key={`activity_${cnt}`}>
        <TableCell sx={{ padding: '8px' }}>{formatDayjs(activityList[cnt].createdAt)}</TableCell>
        <TableCell sx={{ padding: '8px' }}>{activityList[cnt].content}</TableCell>
      </TableRow>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ paddingX: '8px' }}>
      <Typography variant="h5" sx={{ marginBottom: '16px' }}>
        アクティビティ
      </Typography>
      <Table>
        <TableBody>{items}</TableBody>
      </Table>
    </Container>
  );
}

export default PageActivity;
